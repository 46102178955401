import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/config";
import { collection, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore";
import "./Inventory.css";

const Inventory = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingProduct, setEditingProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const product = { id: doc.id, ...doc.data() };
          // Fiyat bilgisini al
          const pricesSnapshot = await getDocs(
            collection(db, `products/${doc.id}/prices`)
          );
          if (!pricesSnapshot.empty) {
            const priceData = pricesSnapshot.docs[0].data();
            product.price = priceData.unit_amount / 100;
          }
          return product;
        })
      );
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm("Bu ürünü silmek istediğinizden emin misiniz?")) {
      try {
        await deleteDoc(doc(db, "products", productId));
        setProducts(products.filter((product) => product.id !== productId));
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };

  const handleEdit = async (e, product) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "products", product.id), {
        name: editingProduct.name,
        description: editingProduct.description,
        stock: parseInt(editingProduct.stock) || 0,
      });
      
      setProducts(products.map((p) => 
        p.id === product.id ? { ...p, ...editingProduct } : p
      ));
      setEditingProduct(null);
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const filteredProducts = products.filter(product => 
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div className="loading">Yükleniyor...</div>;

  return (
    <div className="inventory-container">
      <div className="inventory-header">
        <h2>Stok Yönetimi</h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Ürün ara..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="products-table">
        <table>
          <thead>
            <tr>
              <th>Ürün Görseli</th>
              <th>Ürün Adı</th>
              <th>Kategori</th>
              <th>Fiyat</th>
              <th>Stok</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => (
              <tr key={product.id}>
                <td>
                  <img
                    src={product.images?.[0] || "placeholder.jpg"}
                    alt={product.name}
                    className="product-thumbnail"
                  />
                </td>
                <td>
                  {editingProduct?.id === product.id ? (
                    <input
                      type="text"
                      value={editingProduct.name}
                      onChange={(e) =>
                        setEditingProduct({
                          ...editingProduct,
                          name: e.target.value,
                        })
                      }
                    />
                  ) : (
                    product.name
                  )}
                </td>
                <td>{product.categoryName}</td>
                <td>₺{product.price?.toFixed(2)}</td>
                <td>
                  {editingProduct?.id === product.id ? (
                    <input
                      type="number"
                      value={editingProduct.stock}
                      onChange={(e) =>
                        setEditingProduct({
                          ...editingProduct,
                          stock: e.target.value,
                        })
                      }
                    />
                  ) : (
                    product.stock || 0
                  )}
                </td>
                <td>
                  {editingProduct?.id === product.id ? (
                    <div className="action-buttons">
                      <button
                        className="save-btn"
                        onClick={(e) => handleEdit(e, product)}
                      >
                        <i className="fas fa-save"></i>
                      </button>
                      <button
                        className="cancel-btn"
                        onClick={() => setEditingProduct(null)}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="action-buttons">
                      <button
                        className="edit-btn"
                        onClick={() => setEditingProduct(product)}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                      <button
                        className="delete-btn"
                        onClick={() => handleDelete(product.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Inventory; 