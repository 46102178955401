import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import "./Footer.css";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [contactInfo, setContactInfo] = useState({
    address: '',
    phone: '',
    email: '',
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: ''
    }
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const contactDoc = await getDoc(doc(db, "settings", "contact"));
        if (contactDoc.exists()) {
          const data = contactDoc.data();
          setContactInfo({
            address: data[`address_${i18n.language}`] || data.address,
            phone: data.phone,
            email: data.email,
            social: data.social || {}
          });
        }
      } catch (error) {
        console.error("Error fetching contact info:", error);
      }
    };

    fetchContactInfo();
  }, [i18n.language]);

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>{t('footer.about.title')}</h3>
          <p>{t('footer.about.description')}</p>
        </div>

        <div className="footer-section">
          <h3>{t('footer.quickLinks.title')}</h3>
          <ul>
            <li>
              <Link to="/">{t('footer.quickLinks.home')}</Link>
            </li>
            <li>
              <Link to="/products">{t('footer.quickLinks.products')}</Link>
            </li>
            <li>
              <Link to="/about">{t('footer.quickLinks.about')}</Link>
            </li>
            <li>
              <Link to="/contact">{t('footer.quickLinks.contact')}</Link>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>{t('footer.contact.title')}</h3>
          <ul>
            <li>
              <i className="fas fa-map-marker-alt"></i> {contactInfo.address}
            </li>
            <li>
              <i className="fas fa-phone"></i> {contactInfo.phone}
            </li>
            <li>
              <i className="fas fa-envelope"></i> {contactInfo.email}
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>{t('footer.social.title')}</h3>
          <div className="social-links">
            {contactInfo.social.facebook && (
              <a href={contactInfo.social.facebook} target="_blank" rel="noopener noreferrer" title={t('footer.social.facebook')}>
                <i className="fab fa-facebook"></i>
              </a>
            )}
            {contactInfo.social.twitter && (
              <a href={contactInfo.social.twitter} target="_blank" rel="noopener noreferrer" title={t('footer.social.twitter')}>
                <i className="fab fa-twitter"></i>
              </a>
            )}
            {contactInfo.social.instagram && (
              <a href={contactInfo.social.instagram} target="_blank" rel="noopener noreferrer" title={t('footer.social.instagram')}>
                <i className="fab fa-instagram"></i>
              </a>
            )}
            {contactInfo.social.linkedin && (
              <a href={contactInfo.social.linkedin} target="_blank" rel="noopener noreferrer" title={t('footer.social.linkedin')}>
                <i className="fab fa-linkedin"></i>
              </a>
            )}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 {t('footer.copyright')}</p>
      </div>
    </footer>
  );
};

export default Footer;
