import React, { useState, useEffect } from "react";
import ProductList from "./components/ProductList/ProductList";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./components/Admin/Login";
import Dashboard from "./components/Admin/Dashboard";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import UserLogin from "./components/UserLogin/UserLogin";
import "./i18n";
import { AuthProvider } from "./contexts/AuthContext";
import Profile from "./components/Profile/Profile";
import Cart from "./components/Cart/Cart";
import Checkout from "./components/Checkout/Checkout";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import Orders from "./components/Orders/Orders";
import ProductComponent from "./components/ProductComponent/ProductComponent";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import HomeProducts from "./components/HomeProducts/HomeProducts";

// Auth kontrolü için özel route component'i
const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  return isAuthenticated ? children : <Navigate to="/login" />;
};

// Router içinde kullanılacak içerik bileşeni
function AppContent() {
  const location = useLocation();
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [products, setProducts] = useState([]);

  // Products verilerini yükle
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Firebase veya API'den ürünleri çek
        const productsData = []; // Buraya veri çekme mantığınızı ekleyin
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // LocalStorage işlemleri
  useEffect(() => {
    const savedCart = localStorage.getItem("cartItems");
    if (savedCart) {
      try {
        const parsedCart = JSON.parse(savedCart);
        setCartItems(Array.isArray(parsedCart) ? parsedCart : []);
      } catch (error) {
        console.error("Error parsing cart items:", error);
        setCartItems([]);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  // Hem Navbar hem Footer için aynı koşulu kullanıyoruz
  const showNavbarAndFooter = !location.pathname.includes("/admin/dashboard");

  return (
    <div className="app-container">
      <ScrollToTop />
      {showNavbarAndFooter && (
        <Navbar 
          setIsCartOpen={setIsCartOpen} 
          cartItems={cartItems}
        />
      )}
      <main className="main-content">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <div className="home-page">
                  <HomeProducts
                    products={products}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    setIsCartOpen={setIsCartOpen}
                  />
                  <ProductComponent
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    setIsCartOpen={setIsCartOpen}
                  />
                </div>
              </>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/product/:id"
            element={
              <ProductDetail
                cartItems={cartItems}
                setCartItems={setCartItems}
                setIsCartOpen={setIsCartOpen}
              />
            }
          />
          <Route path="/user/login" element={<UserLogin />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route 
            path="/products" 
            element={
              <div className="products-page">
                <ProductList
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                  setIsCartOpen={setIsCartOpen}
                />
              </div>
            } 
          />
        </Routes>
      </main>
      {showNavbarAndFooter && <Footer />}

      <Cart
        isOpen={isCartOpen}
        setIsOpen={setIsCartOpen}
        cartItems={cartItems}
        setCartItems={setCartItems}
      />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
