import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SuccessPage.css';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 5 saniye sonra ana sayfaya yönlendir
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="success-page">
      <div className="success-container">
        <div className="success-icon">✓</div>
        <h1>Ödeme Başarılı!</h1>
        <p>Siparişiniz başarıyla alındı.</p>
        <p>Sipariş detayları e-posta adresinize gönderilecektir.</p>
        <div className="timer">5 saniye içinde ana sayfaya yönlendirileceksiniz...</div>
        <button onClick={() => navigate('/')} className="home-button">
          Ana Sayfaya Dön
        </button>
      </div>
    </div>
  );
};

export default SuccessPage; 