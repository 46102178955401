import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase/config';
import './UserLogin.css';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Kayıt formu için ek alanlar
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [kvkkAccepted, setKvkkAccepted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isRegistering) {
        // Kayıt işlemi için tüm alanların dolu olduğunu kontrol et
        if (!firstName || !lastName || !phone || !address || !kvkkAccepted) {
          setError(t('userLogin.fillAllFields'));
          return;
        }

        // Firebase Authentication ile kullanıcı oluştur
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Firestore'a kullanıcı bilgilerini kaydet
        await setDoc(doc(db, 'users', user.uid), {
          firstName,
          lastName,
          email,
          phone,
          address,
          kvkkAccepted,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });

        navigate('/'); // Ana sayfaya yönlendir
      } else {
        // Normal giriş işlemi
        await signInWithEmailAndPassword(auth, email, password);
        navigate('/');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  };

  return (
    <div className="user-login-container">
      <div className="user-login-box">
        <h2>{isRegistering ? t('userLogin.register') : t('userLogin.login')}</h2>
        {error && <p className="error-message">{error}</p>}
        
        <form onSubmit={handleSubmit}>
          {isRegistering && (
            <>
              <div className="form-group">
                <label>{t('userLogin.firstName')}</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              
              <div className="form-group">
                <label>{t('userLogin.lastName')}</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>{t('userLogin.phone')}</label>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label>{t('userLogin.address')}</label>
                <textarea
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                  rows="3"
                />
              </div>
            </>
          )}

          <div className="form-group">
            <label>{t('userLogin.email')}</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          
          <div className="form-group">
            <label>{t('userLogin.password')}</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {isRegistering && (
            <div className="form-group kvkk-group">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={kvkkAccepted}
                  onChange={(e) => setKvkkAccepted(e.target.checked)}
                  required
                />
                {t('userLogin.kvkkText')}
              </label>
              <a href="/kvkk" target="_blank" className="kvkk-link">
                {t('userLogin.kvkkLink')}
              </a>
            </div>
          )}

          <button type="submit" className="submit-button">
            {isRegistering ? t('userLogin.registerButton') : t('userLogin.loginButton')}
          </button>
        </form>

        <div className="form-footer">
          <button
            className="toggle-form"
            onClick={() => {
              setIsRegistering(!isRegistering);
              setError('');
            }}
          >
            {isRegistering 
              ? t('userLogin.alreadyHaveAccount')
              : t('userLogin.needAccount')}
          </button>
          {!isRegistering && (
            <button className="forgot-password">
              {t('userLogin.forgotPassword')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserLogin;