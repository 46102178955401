import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import Products from "../Products/ProCard";
import "./ProductComponent.css";
import { useTranslation } from 'react-i18next';

const ProductComponent = ({
  products: initialProducts,
  setIsCartOpen,
  cartItems,
  setCartItems,
  isCartOpen,
}) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState(initialProducts);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products from Firestore...");
        const productsRef = collection(db, "products");
        const querySnapshot = await getDocs(productsRef);

        if (querySnapshot.empty) {
          console.log("No products found in Firestore");
          setProducts([]);
          return;
        }

        const productsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            // Prices koleksiyonunu al
            const pricesRef = collection(doc.ref, "prices");
            const pricesSnapshot = await getDocs(pricesRef);

            // Active olan price'ı bul
            let activePrice = null;
            pricesSnapshot.forEach((priceDoc) => {
              const priceData = priceDoc.data();
              if (priceData.active === true) {
                activePrice = {
                  id: priceDoc.id,
                  currency: priceData.currency,
                  unit_amount: priceData.unit_amount,
                  type: priceData.type,
                };
              }
            });

            // Fiyatı cent'ten dolara çevir
            const price = activePrice ? activePrice.unit_amount / 100 : 0;

            return {
              id: doc.id,
              name: data.name || "",
              price: price,
              currency: activePrice?.currency || "usd",
              description: data.description || "",
              image: data.image || "",
              categoryId: data.categoryId || "",
              images: data.images || [],
              videoUrl: data.videoUrl || null,
              priceId: activePrice?.id,
            };
          })
        );

        console.log("Fetched products:", productsData);
        setProducts(productsData);
        setError(null);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(t('product.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [t]);

  if (loading) {
    return <div className="loading">{t('product.loading')}</div>;
  }

  if (error) {
    return <div className="error">{t('product.error')}</div>;
  }

  if (products.length === 0) {
    return <div className="no-products">{t('product.noProducts')}</div>;
  }

  return (
    <div className="product-component-container">
      <Products 
        products={products}
        setIsCartOpen={setIsCartOpen}
        cartItems={cartItems}
        setCartItems={setCartItems}
        isCartOpen={isCartOpen}
      />
    </div>
  );
};

export default ProductComponent; 