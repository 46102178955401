import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

export const getPaymentConfig = async () => {
  try {
    const settingsDoc = await getDoc(doc(db, 'settings', 'payment'));
    if (!settingsDoc.exists()) {
      throw new Error('Ödeme ayarları bulunamadı');
    }

    const settings = settingsDoc.data();
    
    return {
      isIyzicoEnabled: settings?.iyzico?.enabled || false,
      isTestMode: settings?.iyzico?.sandboxEnabled || true,
      baseUrl: settings?.iyzico?.sandboxEnabled 
        ? 'https://sandbox-api.iyzipay.com'
        : 'https://api.iyzipay.com'
    };

  } catch (error) {
    console.error('Ödeme ayarları yüklenirken hata:', error);
    return {
      isIyzicoEnabled: false,
      isTestMode: true,
      baseUrl: 'https://sandbox-api.iyzipay.com'
    };
  }
};

// İyzico için test kartı bilgileri
export const TEST_CARDS = {
  success: {
    cardNumber: '5528790000000008',
    expireMonth: '12',
    expireYear: '2030',
    cvc: '123',
    cardHolderName: 'John Doe'
  },
  fail: {
    cardNumber: '5406670000000009',
    expireMonth: '12',
    expireYear: '2030',
    cvc: '123',
    cardHolderName: 'John Doe'
  }
}; 