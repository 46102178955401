import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  addDoc,
  increment,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { useAuth } from "../../contexts/AuthContext";
import "./Checkout.css";
import { httpsCallable } from "firebase/functions";
import { getFunctions } from "firebase/functions";
import { getPaymentConfig } from "../../utils/payment";
import { useTranslation } from "react-i18next";
import { useCart } from '../../contexts/CartContext';

const stripePromise = loadStripe(
  "pk_test_51QISSYFsce16XFVAypUOJeDc0ENXp0EuKdCnCkm6s6j81iICQJWwTBwISeFzfJuFBKImYCaGhPOuPZvjpEBPXMPy00GcT1T8Dh"
);

// Kart bilgileri için stil seçenekleri
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

// Ödeme formu bileşeni
const CheckoutForm = ({
  formData,
  cartItems,
  total,
  shippingCost,
  shippingMethod,
  selectedRegion,
  onClose,
  onComplete,
  saveUserData,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState(
    formData.country === "TR" ? "iyzico" : "stripe"
  );

  // Ülke değiştiğinde ödeme yöntemini güncelle
  useEffect(() => {
    setPaymentMethod(formData.country === "TR" ? "iyzico" : "stripe");
  }, [formData.country]);

  // İyzico ödeme başlatma fonksiyonu
  const initializeIyzicoPayment = async (paymentData) => {
    try {
      const functions = getFunctions();
      const initializePayment = httpsCallable(
        functions,
        "initializeIyzicoPayment"
      );
      const result = await initializePayment(paymentData);

      if (result.data.status === "success") {
        // İyzico ödeme formunu aç
        const iframeContainer = document.getElementById("iyzico-checkout-form");
        if (iframeContainer) {
          iframeContainer.innerHTML = result.data.checkoutFormContent;
        }
        return result.data;
      } else {
        throw new Error(result.data.errorMessage || "Ödeme başlatılamadı");
      }
    } catch (error) {
      console.error("İyzico ödeme hatası:", error);
      throw error;
    }
  };

  // Para birimi ve toplam tutarı belirle
  const getCurrencyAndAmount = () => {
    const currency =
      formData.country === "TR"
        ? "TRY"
        : SHIPPING_RATES.international[selectedRegion]?.currency || "USD";

    // Toplam tutarı kuruş/cent cinsinden hesapla
    const totalAmount = Math.round((total + shippingCost) * 100);

    return { currency, totalAmount };
  };

  const saveOrder = async (paymentId) => {
    try {
      const orderData = {
        userId: currentUser.uid,
        paymentId,
        items: cartItems,
        shipping: {
          method: shippingMethod,
          cost: shippingCost,
          address: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            address: formData.address,
            city: formData.city,
            country: formData.country,
            postalCode: formData.postalCode,
            phone: formData.phone,
          },
        },
        total: total + shippingCost,
        status: "paid",
        createdAt: new Date().toISOString(),
        currency: formData.country === "TR" ? "TRY" : "USD",
      };

      // Siparişi Firestore'a kaydet
      const ordersRef = collection(db, "orders");
      await addDoc(ordersRef, orderData);

      // Ürünlerin stok miktarını güncelle
      for (const item of cartItems) {
        const productRef = doc(db, "products", item.id);
        await setDoc(
          productRef,
          {
            stock: increment(-item.quantity),
          },
          { merge: true }
        );
      }

      // Başarılı sipariş sonrası sepeti temizle
      // Not: CartContext'ten clearCart fonksiyonunu props olarak almamız gerekiyor
      if (typeof onComplete === "function") {
        onComplete();
      }
    } catch (error) {
      console.error("Sipariş kaydedilirken hata:", error);
      throw new Error("Sipariş kaydedilirken bir hata oluştu");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (paymentMethod === "iyzico") {
        // İyzico için ödeme verilerini hazırla
        const paymentData = {
          locale: formData.country === "TR" ? "tr" : "en",
          conversationId: `conv_${Date.now()}`,
          price: total,
          paidPrice: total + shippingCost,
          currency: formData.country === "TR" ? "TRY" : "USD",
          basketId: `basket_${Date.now()}`,
          paymentGroup: "PRODUCT",
          buyer: {
            id: currentUser.uid,
            name: formData.firstName,
            surname: formData.lastName,
            email: formData.email,
            identityNumber: "11111111111",
            registrationAddress: formData.address,
            city: formData.city,
            country: formData.country,
          },
          shippingAddress: {
            contactName: `${formData.firstName} ${formData.lastName}`,
            address: formData.address,
            city: formData.city,
            country: formData.country,
          },
          billingAddress: {
            contactName: `${formData.firstName} ${formData.lastName}`,
            address: formData.address,
            city: formData.city,
            country: formData.country,
          },
          basketItems: [
            ...cartItems.map((item) => ({
              id: item.id,
              name: item.name,
              category1: item.category,
              itemType: "PHYSICAL",
              price: item.price,
            })),
            // Kargo ücreti için ayrı bir basket item
            {
              id: "shipping",
              name: "Shipping Cost",
              category1: "Shipping",
              itemType: "VIRTUAL",
              price: shippingCost,
            },
          ],
        };

        // İyzico ödeme işlemini başlat
        const result = await initializeIyzicoPayment(paymentData);
        // ... İyzico işlemleri devam eder
      } else {
        // Backend'den PaymentIntent oluştur
        const functions = getFunctions();
        const createStripePayment = httpsCallable(
          functions,
          "createStripePayment"
        );

        const paymentData = {
          amount: Math.round((total + shippingCost) * 100),
          currency: formData.country === "TR" ? "try" : "usd",
          customer_email: formData.email,
          shipping_address: {
            name: `${formData.firstName} ${formData.lastName}`,
            address: formData.address,
            city: formData.city,
            country: formData.country,
            postal_code: formData.postalCode,
          },
          metadata: {
            order_id: `order_${Date.now()}`,
            shipping_method: shippingMethod,
            cart_items: JSON.stringify(cartItems),
          },
        };

        // PaymentIntent oluştur
        const response = await createStripePayment(paymentData);

        if (!response.data?.clientSecret) {
          throw new Error("PaymentIntent oluşturulamadı");
        }

        // Ödemeyi onayla
        const { error: stripeError, paymentIntent } =
          await stripe.confirmCardPayment(response.data.clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: `${formData.firstName} ${formData.lastName}`,
                email: formData.email,
                address: {
                  line1: formData.address,
                  city: formData.city,
                  country: formData.country,
                  postal_code: formData.postalCode,
                },
              },
            },
          });

        if (stripeError) {
          throw stripeError;
        }

        if (paymentIntent.status === "succeeded") {
          // Ödeme başarılı - siparişi kaydet ve yönlendir
          await saveOrder(paymentIntent.id);
          navigate("/success");
        }
      }
    } catch (err) {
      console.error("Ödeme hatası:", err);
      setError(err.message || "Ödeme işlemi başlatılırken bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="payment-method-selector">
        <h3>{t("checkout.selectPaymentMethod")}</h3>
        <div className="payment-options">
          <label className={formData.country === "TR" ? "disabled" : ""}>
            <input
              type="radio"
              name="paymentMethod"
              value="stripe"
              checked={paymentMethod === "stripe"}
              onChange={(e) => setPaymentMethod(e.target.value)}
              disabled={formData.country === "TR"}
            />
            <span>Stripe {formData.country !== "TR" && "(International)"}</span>
          </label>
          <label className={formData.country !== "TR" ? "disabled" : ""}>
            <input
              type="radio"
              name="paymentMethod"
              value="iyzico"
              checked={paymentMethod === "iyzico"}
              onChange={(e) => setPaymentMethod(e.target.value)}
              disabled={formData.country !== "TR"}
            />
            <span>Iyzico {formData.country === "TR" && "(Yurt İçi)"}</span>
          </label>
        </div>
      </div>

      {paymentMethod === "stripe" ? (
        <div className="card-element-container">
          <label>
            {t("checkout.cardDetails")}
            <CardElement options={CARD_ELEMENT_OPTIONS} />
          </label>
        </div>
      ) : (
        <div id="iyzico-checkout-form" className="iyzico-form">
          {/* İyzico form buraya yüklenecek */}
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      <div className="form-actions">
        <button type="button" onClick={onClose} className="back-button">
          {t("checkout.backToCart")}
        </button>
        <button
          type="submit"
          className="payment-button"
          disabled={paymentMethod === "stripe" ? !stripe || loading : loading}
        >
          {loading ? (
            t("checkout.processing")
          ) : (
            <>
              {t("checkout.completePayment")}
              <span className="payment-amount">
                {formatCurrency(
                  total + shippingCost,
                  formData.country === "TR"
                    ? "TRY"
                    : SHIPPING_RATES.international[selectedRegion]?.currency ||
                        "USD"
                )}
              </span>
            </>
          )}
        </button>
      </div>
    </form>
  );
};

const COUNTRY_REGIONS = {
  'TR': 'domestic',
  'DE': 'europe',
  'FR': 'europe',
  'GB': 'europe',
  'IT': 'europe',
  'ES': 'europe',
  'NL': 'europe',
  'US': 'americas',
  'CA': 'americas',
  'BR': 'americas',
  'JP': 'asia',
  'KR': 'asia',
  'CN': 'asia',
  'IN': 'asia'
};

// Para birimi formatlamak için yardımcı fonksiyon
const formatCurrency = (amount, currency = 'TRY') => {
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(amount);
};

// Kargo fiyatları için sabit değerler
const SHIPPING_RATES = {
  domestic: {
    base: 49.90,
    currency: 'TRY'
  },
  international: {
    europe: {
      base: 249.90,
      currency: 'EUR'
    },
    americas: {
      base: 349.90,
      currency: 'USD'
    },
    asia: {
      base: 299.90,
      currency: 'USD'
    },
    other: {
      base: 399.90,
      currency: 'USD'
    }
  }
};

// Ana Checkout bileşeni
const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const { clearCart } = useCart();
  const { t } = useTranslation();

  // State tanımlamaları
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: currentUser?.email || "",
    phone: "",
    address: "",
    city: "",
    country: "TR",
    postalCode: "",
  });

  // Location state kontrolü
  const cartItems = location.state?.items || [];
  const total = location.state?.total || 0;

  const [shippingMethod, setShippingMethod] = useState("domestic");
  const [selectedRegion, setSelectedRegion] = useState("europe");
  const [shippingCost, setShippingCost] = useState(0);

  // Kargo ücretini hesapla
  const calculateShipping = useCallback((items, method, region = "") => {
    if (method === "domestic") {
      return SHIPPING_RATES.domestic.base;
    } else {
      return (
        SHIPPING_RATES.international[region]?.base ||
        SHIPPING_RATES.international.other.base
      );
    }
  }, []);

  // Kargo ücreti değiştiğinde state'i güncelle
  useEffect(() => {
    const cost = calculateShipping(cartItems, shippingMethod, selectedRegion);
    setShippingCost(cost);
  }, [cartItems, shippingMethod, selectedRegion, calculateShipping]);

  // Form input değişikliklerini handle et
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Formu kapatma/iptal etme işlemi
  const handleClose = () => {
    // Kullanıcıyı sepet sayfasına yönlendir
    navigate('/cart');
  };

  // Kullanıcı bilgilerini kaydetme
  const saveUserData = async () => {
    try {
      if (!currentUser) return;

      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, {
        shippingAddress: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
          city: formData.city,
          country: formData.country,
          postalCode: formData.postalCode,
          phone: formData.phone
        },
        lastUpdated: new Date().toISOString()
      }, { merge: true });

    } catch (error) {
      console.error('Kullanıcı bilgileri kaydedilirken hata:', error);
    }
  };

  // Ödeme tamamlandığında
  const handleComplete = () => {
    clearCart();
    saveUserData(); // Kullanıcı bilgilerini kaydet
    navigate('/success');
  };

  // Loading durumu
  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  // Hata durumu
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="checkout-page">
      <div className="checkout-container">
        <div className="checkout-left">
          <h2>{t("checkout.deliveryInfo")}</h2>
          <form className="delivery-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstName">{t("checkout.firstName")}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">{t("checkout.lastName")}</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">{t("checkout.email")}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">{t("checkout.phone")}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="address">{t("checkout.address")}</label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city">{t("checkout.city")}</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="postalCode">{t("checkout.postalCode")}</label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="country">{t("checkout.country")}</label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              >
                <option value="TR">Türkiye</option>
                <option value="DE">Almanya</option>
                <option value="FR">Fransa</option>
                <option value="GB">İngiltere</option>
                <option value="US">Amerika Birleşik Devletleri</option>
                <option value="CA">Kanada</option>
                <option value="JP">Japonya</option>
                <option value="KR">Güney Kore</option>
                {/* ... diğer ülkeler ... */}
              </select>
            </div>
          </form>

          <div className="payment-section">
            <h2>{t("checkout.paymentInfo")}</h2>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                formData={formData}
                cartItems={cartItems}
                total={total}
                shippingCost={shippingCost}
                shippingMethod={shippingMethod}
                selectedRegion={selectedRegion}
                onClose={handleClose}
                onComplete={handleComplete}
                saveUserData={saveUserData}
              />
            </Elements>
          </div>

          <div className="shipping-options">
            <h3>{t("checkout.shipping.title")}</h3>

            <div className="shipping-method">
              <label
                className={`${shippingMethod === "domestic" ? "active" : ""} ${
                  formData.country !== "TR" ? "disabled" : ""
                }`}
              >
                <input
                  type="radio"
                  name="shipping"
                  value="domestic"
                  checked={shippingMethod === "domestic"}
                  onChange={(e) => setShippingMethod(e.target.value)}
                  disabled={formData.country !== "TR"}
                />
                <div>
                  <div>{t("checkout.shipping.domestic")}</div>
                  <div className="shipping-price">
                    {formatCurrency(
                      SHIPPING_RATES.domestic.base,
                      SHIPPING_RATES.domestic.currency
                    )}
                  </div>
                </div>
              </label>

              <label
                className={`${
                  shippingMethod === "international" ? "active" : ""
                } ${formData.country === "TR" ? "disabled" : ""}`}
              >
                <input
                  type="radio"
                  name="shipping"
                  value="international"
                  checked={shippingMethod === "international"}
                  onChange={(e) => setShippingMethod(e.target.value)}
                  disabled={formData.country === "TR"}
                />
                <div>
                  <div>{t("checkout.shipping.international")}</div>
                  <div className="shipping-price">
                    {formatCurrency(
                      SHIPPING_RATES.international[selectedRegion].base,
                      SHIPPING_RATES.international[selectedRegion].currency
                    )}
                  </div>
                </div>
              </label>
            </div>

            {shippingMethod === "international" && (
              <div className="region-selector">
                <select
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  className="region-select"
                  disabled={true}
                >
                  <option
                    value="europe"
                    disabled={COUNTRY_REGIONS[formData.country] !== "europe"}
                  >
                    {t("checkout.shipping.regions.europe")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.europe.base,
                      SHIPPING_RATES.international.europe.currency
                    )}
                  </option>
                  <option
                    value="americas"
                    disabled={COUNTRY_REGIONS[formData.country] !== "americas"}
                  >
                    {t("checkout.shipping.regions.americas")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.americas.base,
                      SHIPPING_RATES.international.americas.currency
                    )}
                  </option>
                  <option
                    value="asia"
                    disabled={COUNTRY_REGIONS[formData.country] !== "asia"}
                  >
                    {t("checkout.shipping.regions.asia")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.asia.base,
                      SHIPPING_RATES.international.asia.currency
                    )}
                  </option>
                  <option
                    value="other"
                    disabled={COUNTRY_REGIONS[formData.country] !== "other"}
                  >
                    {t("checkout.shipping.regions.other")} -{" "}
                    {formatCurrency(
                      SHIPPING_RATES.international.other.base,
                      SHIPPING_RATES.international.other.currency
                    )}
                  </option>
                </select>
              </div>
            )}

            <div className="shipping-summary">
              <div className="shipping-cost">
                <span>{t("checkout.shipping.cost")}</span>
                <span>₺{shippingCost.toFixed(2)}</span>
              </div>
              <div className="estimated-delivery">
                <span>{t("checkout.shipping.estimatedDelivery")}</span>
                <span>
                  {shippingMethod === "domestic"
                    ? t("checkout.shipping.domesticDelivery")
                    : t("checkout.shipping.internationalDelivery")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="checkout-right">
          <div className="order-summary">
            <h2>{t("checkout.orderSummary")}</h2>
            <div className="price-summary">
              <div className="subtotal">
                <span>{t("checkout.subtotal")}</span>
                <span>{formatCurrency(total, "TRY")}</span>
              </div>

              <div className="shipping-cost">
                <span>{t("checkout.shipping.cost")}</span>
                <span>₺{shippingCost.toFixed(2)}</span>
              </div>

              <div className="estimated-delivery">
                <span>{t("checkout.shipping.estimatedDelivery")}</span>
                <span>
                  {shippingMethod === "domestic"
                    ? t("checkout.shipping.domesticDelivery")
                    : t("checkout.shipping.internationalDelivery")}
                </span>
              </div>

              <div className="total">
                <span>{t("checkout.total")}</span>
                <span>{formatCurrency(total + shippingCost, "TRY")}</span>
              </div>
            </div>
          </div>

          <div className="secure-checkout-info">
            <div className="secure-icons">🔒</div>
            <p>{t("checkout.securePaymentInfo")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
