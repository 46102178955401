import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import "./ProductDetail.css";
import { loadStripe } from "@stripe/stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(
  "pk_test_51QISSYFsce16XFVAypUOJeDc0ENXp0EuKdCnCkm6s6j81iICQJWwTBwISeFzfJuFBKImYCaGhPOuPZvjpEBPXMPy00GcT1T8Dh"
);
const functions = getFunctions();

const ProductDetail = ({ addToCart, setIsCartOpen, cartItems, setCartItems }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalMediaIndex, setModalMediaIndex] = useState(0);
  const modalVideoRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Fetched product data:", data);

          const pricesSnapshot = await getDocs(
            collection(db, `products/${docSnap.id}/prices`)
          );
          let price = 0;

          if (!pricesSnapshot.empty) {
            const firstPrice = pricesSnapshot.docs[0].data();
            price = firstPrice.unit_amount / 100;
          }

          setProduct({
            id: docSnap.id,
            ...data,
            price: price,
            allMedia: [
              ...(data.video ? [{ type: "video", url: data.video }] : []),
              ...(data.images?.map((img) => ({ type: "image", url: img })) || []),
            ],
          });
        } else {
          setError("Ürün bulunamadı!");
        }
      } catch (error) {
        console.error("Error fetching product: ", error);
        setError("Ürün yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleMediaClick = (index) => {
    setModalMediaIndex(index);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalVideoRef.current) {
      modalVideoRef.current.pause();
    }
  };

  const handleModalNavigation = (direction) => {
    if (!product?.allMedia) return;
    
    if (direction === 'prev') {
      setModalMediaIndex(prev => 
        prev === 0 ? product.allMedia.length - 1 : prev - 1
      );
    } else {
      setModalMediaIndex(prev => 
        prev === product.allMedia.length - 1 ? 0 : prev + 1
      );
    }
  };

  const renderModalMedia = () => {
    if (!product?.allMedia) return null;
    const currentMedia = product.allMedia[modalMediaIndex];

    return (
      <div className="modal-media-container">
        {currentMedia.type === "video" ? (
          <video
            ref={modalVideoRef}
            src={currentMedia.url}
            className="modal-media video"
            controls
            autoPlay
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <img
            src={currentMedia.url}
            alt={product.name}
            className="modal-media image"
            onClick={(e) => e.stopPropagation()}
          />
        )}
        
        <button 
          className="modal-nav prev" 
          onClick={(e) => {
            e.stopPropagation();
            handleModalNavigation('prev');
          }}
        >
          ‹
        </button>
        <button 
          className="modal-nav next"
          onClick={(e) => {
            e.stopPropagation();
            handleModalNavigation('next');
          }}
        >
          ›
        </button>

        <div className="modal-thumbnails">
          {product.allMedia.map((media, index) => (
            <div
              key={index}
              className={`modal-thumbnail ${modalMediaIndex === index ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setModalMediaIndex(index);
              }}
            >
              {media.type === "video" ? (
                <div className="video-thumbnail">
                  <video src={media.url} />
                  <span className="video-icon">▶</span>
                </div>
              ) : (
                <img src={media.url} alt="" />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMedia = () => {
    if (!product?.allMedia?.length) return null;

    const currentMedia = product.allMedia[currentMediaIndex];

    return (
      <div className="media-container">
        {currentMedia.type === "video" ? (
          <video
            key={currentMedia.url}
            ref={videoRef}
            src={currentMedia.url}
            className="product-media video"
            autoPlay
            muted
            loop
            playsInline
            controls
            onClick={() => handleMediaClick(currentMediaIndex)}
            onError={(e) => console.error("Video error:", e)}
          />
        ) : (
          <img
            src={currentMedia.url}
            alt={product.name}
            className="product-media image"
            onClick={() => handleMediaClick(currentMediaIndex)}
            style={{ cursor: 'pointer' }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/400x400?text=No+Image";
            }}
          />
        )}

        {product.allMedia.length > 1 && (
          <>
            <button
              className="media-nav prev"
              onClick={(e) => {
                e.preventDefault();
                setCurrentMediaIndex((prev) =>
                  prev === 0 ? product.allMedia.length - 1 : prev - 1
                );
              }}
            >
              ‹
            </button>
            <button
              className="media-nav next"
              onClick={(e) => {
                e.preventDefault();
                setCurrentMediaIndex((prev) =>
                  prev === product.allMedia.length - 1 ? 0 : prev + 1
                );
              }}
            >
              ›
            </button>
            <div className="media-dots">
              {product.allMedia.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${currentMediaIndex === index ? 'active' : ''}`}
                  onClick={() => setCurrentMediaIndex(index)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const handlePurchase = () => {
    if (!product) return;

    const newItem = {
      id: product.id,
      title: product.name,
      price: product.price,
      image: product.allMedia?.[0]?.url || 'https://via.placeholder.com/400x400?text=No+Image',
      quantity: 1,
      currency: "try"
    };

    const existingItemIndex = cartItems.findIndex(item => item.id === product.id);

    if (existingItemIndex !== -1) {
      const updatedItems = [...cartItems];
      updatedItems[existingItemIndex].quantity += 1;
      setCartItems(updatedItems);
    } else {
      setCartItems(prev => [...prev, newItem]);
    }

    setIsCartOpen(true);
  };

  if (loading) return <div className="loading">Yükleniyor...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!product) return <div className="error">Ürün bulunamadı!</div>;

  return (
    <>
      <div className="product-detail">
        <div className="product-detail-container">
          <div className="product-gallery">
            {renderMedia()}
            <div className="thumbnails">
              {product?.allMedia?.map((media, index) => (
                <div
                  key={index}
                  className={`thumbnail ${currentMediaIndex === index ? "active" : ""}`}
                  onClick={() => setCurrentMediaIndex(index)}
                >
                  {media.type === "video" ? (
                    <div className="video-thumbnail">
                      <video src={media.url} className="thumbnail-media" />
                      <span className="video-icon">▶</span>
                    </div>
                  ) : (
                    <img src={media.url} alt="" className="thumbnail-media" />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="product-info-detailed">
            <h1>{product.name}</h1>
            <p className="category">{product.metadata?.category}</p>
            <div className="price-section">
              <span className="price">
                {product.price
                  ? `₺${product.price.toFixed(2)}`
                  : t('product.price')}
              </span>
              <button 
                className="purchase-button"
                onClick={handlePurchase}
                disabled={!product}
              >
                {t('product.addToCart')}
              </button>
            </div>
            <div className="product-description">
              <h2>{t('product.description')}</h2>
              <p>{product.description}</p>
            </div>
            {product.metadata?.stock !== undefined && (
              <div className="stock-info">
                <h2>{t('product.stockStatus')}</h2>
                <p>
                  {product.metadata.stock > 0 
                    ? `${product.metadata.stock} ${t('product.pieces')}` 
                    : t('product.outOfStock')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="media-modal" onClick={handleModalClose}>
          <span className="close-modal" onClick={handleModalClose}>{t('modal.close')}</span>
          {renderModalMedia()}
          <button 
            className="modal-nav prev" 
            onClick={(e) => {
              e.stopPropagation();
              handleModalNavigation('prev');
            }}
          >
            {t('modal.previous')}
          </button>
          <button 
            className="modal-nav next"
            onClick={(e) => {
              e.stopPropagation();
              handleModalNavigation('next');
            }}
          >
            {t('modal.next')}
          </button>
        </div>
      )}
    </>
  );
};

ProductDetail.propTypes = {
  setIsCartOpen: PropTypes.func.isRequired,
  cartItems: PropTypes.array,
  setCartItems: PropTypes.func.isRequired
};

export default ProductDetail;
