import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Hero.css";
import hero from "../../assets/images/hero.jpg";
import hero1 from "../../assets/images/hero1.jpg";

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: "linear",
  };

  const slides = [
    {
      image: hero,
      title: "Modern Sanat Eserleri",
      subtitle: "Özgün ve Etkileyici Figürler",
    },
    {
      image: hero1,
      title: "Özel Koleksiyonlar",
      subtitle: "Benzersiz Sanat Deneyimi",
    },
  ];

  return (
    <div className="hero-section">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="hero-slide">
            <div className="slide-content">
              <div className="slide-overlay"></div>
              <img src={slide.image} alt={slide.title} />
              <div className="slide-text">
                <h1>{slide.title}</h1>
                <p>{slide.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hero;
