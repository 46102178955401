import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/config";
import "./ProductCard.css";

const ProductCard = ({ product, setIsCartOpen, cartItems, setCartItems }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Fiyat bilgisini çek
        const pricesSnapshot = await getDocs(
          collection(db, `products/${product.id}/prices`)
        );
        let price = 0;

        if (!pricesSnapshot.empty) {
          const firstPrice = pricesSnapshot.docs[0].data();
          price = firstPrice.unit_amount / 100;
        }

        // Medya listesini oluştur
        const allMedia = [];
        
        // Video kontrolleri
        if (product.videoUrl) {
          console.log("Video URL bulundu:", product.videoUrl);
          allMedia.push({ type: 'video', url: product.videoUrl });
        }
        if (product.video) {
          console.log("Video bulundu:", product.video);
          allMedia.push({ type: 'video', url: product.video });
        }
        
        // Resim kontrolleri
        if (product.images && product.images.length > 0) {
          console.log("Resimler bulundu:", product.images);
          product.images.forEach(img => {
            allMedia.push({ type: 'image', url: img });
          });
        } else if (product.image) {
          console.log("Tek resim bulundu:", product.image);
          allMedia.push({ type: 'image', url: product.image });
        }

        console.log("Oluşturulan medya listesi:", allMedia);

        setProductData({
          ...product,
          price: price,
          allMedia: allMedia
        });
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [product]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price);
  };

  const mediaList = productData?.allMedia || [];

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (productData?.allMedia?.[currentMediaIndex]?.type === 'video' && videoRef.current) {
      console.log("Video oynatılıyor...");
      videoRef.current.play().catch(error => {
        console.log("Video otomatik oynatılamadı:", error);
      });
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) {
      console.log("Video durduruluyor...");
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!productData) return;

    const newItem = {
      id: productData.id,
      name: productData.name,
      price: productData.price,
      image: productData.allMedia?.[0]?.url || "https://via.placeholder.com/300x300",
      quantity: 1,
      priceId: productData.priceId
    };

    const existingItem = cartItems.find(item => item.id === productData.id);
    
    if (existingItem) {
      setCartItems(cartItems.map(item =>
        item.id === productData.id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      ));
    } else {
      setCartItems([...cartItems, newItem]);
    }
    
    setIsCartOpen(true);
  };

  const nextMedia = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentMediaIndex((prev) => (prev + 1) % mediaList.length);
  };

  const prevMedia = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentMediaIndex((prev) => (prev - 1 + mediaList.length) % mediaList.length);
  };

  const renderMedia = () => {
    if (!productData?.allMedia?.length) {
      console.log("Medya bulunamadı");
      return (
        <img 
          src="https://via.placeholder.com/300x300"
          alt={productData?.name || "Ürün"}
          className="product-media"
        />
      );
    }

    const currentMedia = productData.allMedia[currentMediaIndex];
    console.log("Gösterilen medya:", currentMedia);

    if (currentMedia.type === 'video') {
      return (
        <>
          <video
            ref={videoRef}
            src={currentMedia.url}
            className="product-media"
            loop
            muted
            playsInline
            preload="metadata"
            poster={productData.image}
            onLoadedMetadata={(e) => {
              console.log("Video yüklendi");
              if (isHovered) {
                e.target.play().catch(console.error);
              }
            }}
          />
          <div className="video-indicator">▶</div>
        </>
      );
    }

    return (
      <img 
        src={currentMedia.url} 
        alt={productData.name}
        className="product-media"
        onError={(e) => {
          console.log("Resim yüklenemedi");
          e.target.src = "https://via.placeholder.com/300x300";
        }}
      />
    );
  };

  const isSoldOut = !product.stock || product.stock === 0;

  if (!productData) return null;

  return (
    <Link to={`/product/${productData.id}`} className="product-card">
      <div 
        className="product-media-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {renderMedia()}
        
        {mediaList.length > 1 && isHovered && (
          <>
            <button className="media-nav prev" onClick={prevMedia}>‹</button>
            <button className="media-nav next" onClick={nextMedia}>›</button>
          </>
        )}
        
        {isSoldOut ? (
          <div className="sold-out-overlay">
            <div className="sold-out-badge">{t("product.soldOut")}</div>
          </div>
        ) : productData.onSale && (
          <span className="sale-badge">{t("product.onSale")}</span>
        )}
      </div>

      <div className="product-info">
        <h3 className="product-name">{productData.name}</h3>
        <div className="product-price">
          {productData.oldPrice && (
            <span className="old-price">{formatPrice(productData.oldPrice)}</span>
          )}
          <span className="current-price">
            {productData.price ? formatPrice(productData.price) : t('product.price')}
          </span>
        </div>
        <button 
          className={`add-to-cart-btn ${isSoldOut ? 'disabled' : ''}`}
          onClick={isSoldOut ? null : handleAddToCart}
          disabled={isSoldOut}
        >
          {isSoldOut ? t("product.soldOut") : t("product.addToCart")}
        </button>
      </div>
    </Link>
  );
};

export default ProductCard;
