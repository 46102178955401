import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCd7vrxl8SxZCM9UoW3Y1IMNwdbSA55skA",
  authDomain: "onurart-439e1.firebaseapp.com",
  projectId: "onurart-439e1",
  storageBucket: "onurart-439e1.firebasestorage.app",
  messagingSenderId: "569926116186",
  appId: "1:569926116186:web:ef40b26bdfc37c305f8963",
  measurementId: "G-9SFKRQHGT4",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

// Auth durumu değişikliklerini dinlemek için
auth.onAuthStateChanged((user) => {
  if (user) {
    // Kullanıcı oturum açtı
    console.log("Oturum açıldı:", user.uid);
  } else {
    // Kullanıcı oturumu kapattı
    console.log("Oturum kapatıldı");
  }
});
