import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/config";
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import "./Categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: "", description: "" });
  const [editingCategory, setEditingCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  // Kategorileri yükle
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "categories"));
      const categoriesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Yeni kategori ekle
  const handleAddCategory = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await addDoc(collection(db, "categories"), {
        name: newCategory.name,
        description: newCategory.description,
        createdAt: new Date()
      });
      setNewCategory({ name: "", description: "" });
      fetchCategories();
    } catch (error) {
      console.error("Error adding category:", error);
    } finally {
      setLoading(false);
    }
  };

  // Kategori sil
  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm("Bu kategoriyi silmek istediğinizden emin misiniz?")) {
      try {
        await deleteDoc(doc(db, "categories", categoryId));
        fetchCategories();
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    }
  };

  // Kategori düzenle
  const handleEditCategory = async (e) => {
    e.preventDefault();
    if (!editingCategory) return;

    try {
      await updateDoc(doc(db, "categories", editingCategory.id), {
        name: editingCategory.name,
        description: editingCategory.description,
        updatedAt: new Date()
      });
      setEditingCategory(null);
      fetchCategories();
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <div className="categories-container">
      <div className="categories-header">
        <h2>Kategori Yönetimi</h2>
      </div>

      <div className="categories-content">
        {/* Yeni Kategori Ekleme Formu */}
        <div className="category-form-container">
          <h3>Yeni Kategori Ekle</h3>
          <form onSubmit={handleAddCategory} className="category-form">
            <div className="form-group">
              <label>Kategori Adı:</label>
              <input
                type="text"
                value={newCategory.name}
                onChange={(e) => setNewCategory({...newCategory, name: e.target.value})}
                required
              />
            </div>
            <div className="form-group">
              <label>Açıklama:</label>
              <textarea
                value={newCategory.description}
                onChange={(e) => setNewCategory({...newCategory, description: e.target.value})}
              />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Ekleniyor..." : "Kategori Ekle"}
            </button>
          </form>
        </div>

        {/* Kategoriler Listesi */}
        <div className="categories-list">
          <h3>Mevcut Kategoriler</h3>
          <div className="categories-grid">
            {categories.map((category) => (
              <div key={category.id} className="category-card">
                {editingCategory?.id === category.id ? (
                  <form onSubmit={handleEditCategory} className="edit-form">
                    <input
                      type="text"
                      value={editingCategory.name}
                      onChange={(e) => setEditingCategory({
                        ...editingCategory,
                        name: e.target.value
                      })}
                    />
                    <textarea
                      value={editingCategory.description}
                      onChange={(e) => setEditingCategory({
                        ...editingCategory,
                        description: e.target.value
                      })}
                    />
                    <div className="edit-actions">
                      <button type="submit">Kaydet</button>
                      <button type="button" onClick={() => setEditingCategory(null)}>
                        İptal
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <h4>{category.name}</h4>
                    <p>{category.description}</p>
                    <div className="category-actions">
                      <button onClick={() => setEditingCategory(category)}>
                        <i className="fas fa-edit"></i>
                      </button>
                      <button onClick={() => handleDeleteCategory(category.id)}>
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories; 