import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { auth, db } from '../../firebase/config';
import { signOut } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import "./Navbar.css";
import logo from "../../assets/logo/logo.png";
import { FaShoppingCart, FaSearch, FaGlobe, FaHourglassHalf, FaUser } from 'react-icons/fa';

const Navbar = ({ cartItems = [], setIsCartOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();

  // Dışarı tıklandığında arama kutusunu kapat
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Arama fonksiyonu
  const handleSearch = async (value) => {
    setSearchTerm(value);
    if (value.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const productsRef = collection(db, 'products');
      const searchValue = value.toLowerCase().trim();
      
      // Tüm ürünleri çek
      const querySnapshot = await getDocs(productsRef);
      
      // Client-side filtreleme yap
      const filteredProducts = [];
      
      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        const name = (data.name || '').toLowerCase();
        const description = (data.description || '').toLowerCase();
        
        // Hem isim hem de açıklamada ara
        if (name.includes(searchValue) || description.includes(searchValue)) {
          // Fiyat bilgisini al
          const pricesSnapshot = await getDocs(collection(doc.ref, 'prices'));
          let price = 0;

          if (!pricesSnapshot.empty) {
            const firstPrice = pricesSnapshot.docs[0].data();
            price = firstPrice.unit_amount / 100;
          }

          filteredProducts.push({
            id: doc.id,
            name: data.name,
            description: data.description,
            price: price,
            image: data.images?.[0] || data.image,
          });
        }
      }

      setSearchResults(filteredProducts);
    } catch (error) {
      console.error('Search error:', error);
    }
  };

  const handleSearchResultClick = (productId) => {
    navigate(`/product/${productId}`);
    setIsSearchOpen(false);
    setSearchTerm("");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleOrdersClick = () => {
    setIsProfileMenuOpen(false);
    navigate('/orders');
  };

  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

  // Mobil menü açıkken scroll'u engelle
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  // Backdrop'a tıklandığında menüyü kapat
  const handleBackdropClick = () => {
    setIsMenuOpen(false);
    setIsProfileMenuOpen(false);
    setIsSearchOpen(false);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="Logo" className="logo-image" />
          </Link>

          <button
            className="menu-toggle"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className={`hamburger ${isMenuOpen ? "active" : ""}`}></span>
          </button>

          <div className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
            <Link to="/products" className="nav-link">
              {t('nav.products')}
            </Link>
            <Link to="/about" className="nav-link">
              {t('nav.about')}
            </Link>
            <Link to="/contact" className="nav-link">
              {t('nav.contact')}
            </Link>
            <Link to="/coming-soon" className="nav-link coming-soon">
              <FaHourglassHalf className="coming-soon-icon" />
              {t('nav.comingSoon')}
            </Link>
          </div>

          <div className={`navbar-actions ${isMenuOpen ? "active" : ""}`}>
            <div className="search-container" ref={searchRef}>
              <button 
                className="icon-button search-button"
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                data-label={t('nav.search')}
              >
                <FaSearch />
              </button>

              <div className={`search-input-container ${isSearchOpen ? 'active' : ''}`}>
                <input
                  type="text"
                  className="search-input"
                  placeholder={t('search.placeholder')}
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  autoFocus
                />
                {searchResults.length > 0 ? (
                  <div className="search-results">
                    {searchResults.map((result) => (
                      <div
                        key={result.id}
                        className="search-result-item"
                        onClick={() => handleSearchResultClick(result.id)}
                      >
                        <img
                          src={result.image || "https://via.placeholder.com/40"}
                          alt={result.name}
                          className="search-result-image"
                        />
                        <div className="search-result-info">
                          <div className="search-result-name">{result.name}</div>
                          <div className="search-result-description">
                            {result.description?.substring(0, 50)}...
                          </div>
                          <div className="search-result-price">
                            ₺{result.price.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : searchTerm.length >= 2 ? (
                  <div className="search-no-results">
                    {t('search.noResults')}
                  </div>
                ) : null}
              </div>
            </div>
            
            <button 
              className="icon-button cart-button" 
              onClick={() => setIsCartOpen(true)}
              data-label={t('nav.cart')}
            >
              <FaShoppingCart />
              {cartItemCount > 0 && <span className="cart-count">{cartItemCount}</span>}
            </button>

            {currentUser ? (
              <div className="profile-menu-container">
                <button 
                  className="profile-button"
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                >
                  <span className="profile-icon">👤</span>
                  <span className="profile-name">{userData?.firstName || currentUser.email}</span>
                </button>
                {isProfileMenuOpen && (
                  <div className="profile-dropdown">
                    <div className="profile-info">
                      <p className="user-name">{userData?.firstName} {userData?.lastName}</p>
                      <p className="user-email">{currentUser.email}</p>
                    </div>
                    <div className="profile-menu-items">
                      <Link to="/profile" className="profile-menu-item">
                        {t('nav.profile')}
                      </Link>
                      <button 
                        onClick={handleOrdersClick} 
                        className="profile-menu-item"
                      >
                        {t('nav.orders')}
                      </button>
                      <button 
                        onClick={handleLogout} 
                        className="profile-menu-item logout"
                      >
                        {t('nav.logout')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link to="/user/login" className="nav-action">
                <FaUser />
              </Link>
            )}

            <div className="language-selector">
              <FaGlobe className="language-icon" />
              <button onClick={() => changeLanguage('tr')} className={i18n.language === 'tr' ? 'active' : ''}>
                🇹🇷 TR
              </button>
              <button onClick={() => changeLanguage('en')} className={i18n.language === 'en' ? 'active' : ''}>
                🇬🇧 EN
              </button>
            </div>
          </div>
        </div>
      </nav>
      
      {/* Mobil menü backdrop */}
      <div 
        className={`mobile-menu-backdrop ${isMenuOpen ? 'active' : ''}`}
        onClick={handleBackdropClick}
      />
    </>
  );
};

export default Navbar;
