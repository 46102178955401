import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../contexts/AuthContext';
import './Orders.css';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      if (!currentUser) {
        console.log("Kullanıcı yok");
        return;
      }

      try {
        console.log("Aranan Kullanıcı ID:", currentUser.uid);
        
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('userId', '==', currentUser.uid)
        );

        const querySnapshot = await getDocs(q);
        console.log("Bulunan sipariş sayısı:", querySnapshot.size);
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log("Sipariş ID:", doc.id);
          console.log("Sipariş userId:", data.userId);
          console.log("Current User ID:", currentUser.uid);
        });
        
        const ordersData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            orderDate: data.createdAt,
            items: data.items || [],
            shipping: data.shipping || {},
            status: data.status || 'pending',
            totalAmount: data.total || 0,
            paymentIntentId: data.paymentIntentId,
            userId: data.userId
          };
        });

        const userOrders = ordersData.filter(order => order.userId === currentUser.uid);
        console.log("Filtrelenmiş siparişler:", userOrders);
        
        setOrders(userOrders);
      } catch (error) {
        console.error('Siparişler yüklenirken hata:', error);
        console.log('Hata detayı:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentUser]);

  const getOrderStatusText = (status) => {
    const statusMap = {
      'pending': 'Beklemede',
      'processing': 'İşleniyor',
      'shipped': 'Kargoya Verildi',
      'delivered': 'Teslim Edildi',
      'cancelled': 'İptal Edildi'
    };
    return statusMap[status] || status;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatOrderNumber = (orderId) => {
    if (!orderId) return 'N/A';
    // Son 6 karakteri al
    return `#${orderId.slice(-6).toUpperCase()}`;
  };

  const texts = {
    tr: {
      title: "Siparişlerim",
      noOrders: "Henüz siparişiniz bulunmamaktadır.",
      loading: "Siparişleriniz yükleniyor...",
      order: "Sipariş",
      quantity: "Adet",
      unitPrice: "Birim Fiyat",
      total: "Toplam",
      totalAmount: "Toplam Tutar",
      totalItems: "Toplam Ürün",
      pieces: "adet",
      shippingInfo: "Teslimat Bilgileri",
      recipient: "Alıcı",
      phone: "Telefon",
      address: "Adres",
      paymentInfo: "Ödeme Bilgileri",
      paymentMethod: "Ödeme Yöntemi",
      paymentStatus: "Ödeme Durumu",
      creditCard: "Kredi Kartı",
      paid: "Ödendi",
      status: {
        pending: "Beklemede",
        processing: "İşleniyor",
        shipped: "Kargoya Verildi",
        delivered: "Teslim Edildi",
        cancelled: "İptal Edildi"
      }
    }
  };

  const currentLang = 'tr';
  const t = texts[currentLang];

  const getStatusColor = (status) => {
    const colors = {
      pending: "#f0ad4e",
      processing: "#5bc0de",
      shipped: "#0275d8",
      delivered: "#5cb85c",
      cancelled: "#d9534f"
    };
    return colors[status] || colors.pending;
  };

  if (loading) {
    return (
      <div className="orders-page">
        <div className="orders-loading">{t.loading}</div>
      </div>
    );
  }

  return (
    <div className="orders-page">
      <div className="orders-container">
        <h2>{t.title}</h2>
        {loading ? (
          <div className="orders-loading">{t.loading}</div>
        ) : orders.length === 0 ? (
          <p className="no-orders">{t.noOrders}</p>
        ) : (
          <div className="orders-list">
            {orders.map((order) => (
              <div key={order.id} className="order-card">
                <div className="order-header">
                  <div className="order-info">
                    <h3>{t.order} {formatOrderNumber(order.id)}</h3>
                    <p className="order-date">
                      {order.orderDate ? formatDate(order.orderDate) : 'N/A'}
                    </p>
                  </div>
                  <span 
                    className="status-badge"
                    style={{ backgroundColor: getStatusColor(order.status) }}
                  >
                    {t.status[order.status] || t.status.pending}
                  </span>
                </div>

                <div className="order-items">
                  {order.items?.map((item, index) => (
                    <div key={index} className="order-item">
                      <img src={item.image} alt={item.title} />
                      <div className="item-info">
                        <h4>{item.title}</h4>
                        <div className="item-details">
                          <p>Adet: {item.quantity}</p>
                          <p>Birim Fiyat: ${Number(item.price || 0).toFixed(2)}</p>
                          <p>Toplam: ${Number(item.price * (item.quantity || 1)).toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="order-footer">
                  <div className="order-summary">
                    <div className="order-total">
                      <strong>Toplam Tutar:</strong>
                      <span>${Number(order.totalAmount || 0).toFixed(2)}</span>
                    </div>
                  </div>

                  {order.shipping && (
                    <div className="shipping-info">
                      <h4>Teslimat Bilgileri</h4>
                      <p><strong>Adres:</strong> {order.shipping.address}</p>
                      <p>{order.shipping.city}</p>
                    </div>
                  )}

                  <div className="payment-info">
                    <h4>Ödeme Bilgileri</h4>
                    <p>
                      <strong>Ödeme Yöntemi:</strong> Kredi Kartı
                    </p>
                    <p>
                      <strong>Ödeme Durumu:</strong> 
                      {order.status === 'paid' ? 'Ödendi' : order.status}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;