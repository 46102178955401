import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase/config';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './PaymentSettings.css';

const PaymentSettings = () => {
  const [settings, setSettings] = useState({
    iyzico: {
      enabled: false,
      apiKey: '',
      secretKey: '',
      baseUrl: 'https://api.iyzipay.com', // Prod URL
      sandboxEnabled: true,
    }
  });
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'payment'));
      if (settingsDoc.exists()) {
        setSettings(settingsDoc.data());
      }
    } catch (err) {
      setError('Ayarlar yüklenirken hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await setDoc(doc(db, 'settings', 'payment'), settings);
      setSuccess(true);
    } catch (err) {
      setError('Ayarlar kaydedilirken hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      iyzico: {
        ...prev.iyzico,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };

  if (loading) return <div className="loading">Yükleniyor...</div>;

  return (
    <div className="payment-settings">
      <h2>Ödeme Ayarları</h2>
      
      <form onSubmit={handleSubmit}>
        <div className="settings-section">
          <h3>İyzico Entegrasyonu</h3>
          
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="enabled"
                checked={settings.iyzico.enabled}
                onChange={handleChange}
              />
              İyzico Ödeme Sistemini Aktif Et
            </label>
          </div>

          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="sandboxEnabled"
                checked={settings.iyzico.sandboxEnabled}
                onChange={handleChange}
              />
              Test Modu (Sandbox)
            </label>
          </div>

          <div className="form-group">
            <label>API Key</label>
            <input
              type="text"
              name="apiKey"
              value={settings.iyzico.apiKey}
              onChange={handleChange}
              placeholder="İyzico API Key"
            />
          </div>

          <div className="form-group">
            <label>Secret Key</label>
            <input
              type="password"
              name="secretKey"
              value={settings.iyzico.secretKey}
              onChange={handleChange}
              placeholder="İyzico Secret Key"
            />
          </div>
        </div>

        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">Ayarlar başarıyla kaydedildi!</div>}

        <button type="submit" className="save-button" disabled={loading}>
          {loading ? 'Kaydediliyor...' : 'Kaydet'}
        </button>
      </form>
    </div>
  );
};

export default PaymentSettings; 