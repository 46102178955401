import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import "./OrderManagement.css";

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersRef = collection(db, "orders");
        const querySnapshot = await getDocs(ordersRef);

        const ordersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const sortedOrders = ordersList.sort((a, b) => {
          const dateA = new Date(a.orderDate);
          const dateB = new Date(b.orderDate);
          return dateB - dateA;
        });

        setOrders(sortedOrders);
        setError(null);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError("Siparişler yüklenirken bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const formatDateTime = (timestamp) => {
    if (!timestamp) return "Bilinmiyor";
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  const handleStatusChange = async (newStatus) => {
    if (selectedOrder) {
      try {
        const orderRef = doc(db, "orders", selectedOrder.id);
        await updateDoc(orderRef, { status: newStatus });
        setSelectedOrder({ ...selectedOrder, status: newStatus });
        setOrders(
          orders.map((order) =>
            order.id === selectedOrder.id
              ? { ...order, status: newStatus }
              : order
          )
        );
      } catch (err) {
        console.error("Error updating order status:", err);
      }
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "status-pending";
      case "preparing":
        return "status-preparing";
      case "shipped":
        return "status-shipped";
      case "delivered":
        return "status-delivered";
      default:
        return "";
    }
  };

  const handleStatusChangeInList = async (orderId, newStatus, event) => {
    event.stopPropagation();
    try {
      const orderRef = doc(db, "orders", orderId);
      await updateDoc(orderRef, { status: newStatus });
      setOrders(
        orders.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
    } catch (err) {
      console.error("Error updating order status:", err);
    }
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="order-management">
      <h2>Sipariş Yönetimi</h2>
      <table className="orders-table">
        <thead>
          <tr>
            <th>Sipariş No</th>
            <th>Müşteri</th>
            <th>Tarih</th>
            <th>Tutar</th>
            <th>Durum</th>
            <th>Adres</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr
              key={order.id}
              onClick={() => handleOrderClick(order)}
              className={getStatusClass(order.status)}
            >
              <td>{order.orderNumber || "Bilinmiyor"}</td>
              <td>{order.customerInfo?.name || "Bilinmiyor"}</td>
              <td>{formatDateTime(order.createdAt)}</td>
              <td>
                {order.payment?.currency} {order.totalAmount || 0}
              </td>
              <td onClick={(e) => e.stopPropagation()}>
                <select
                  value={order.status || "pending"}
                  onChange={(e) =>
                    handleStatusChangeInList(order.id, e.target.value, e)
                  }
                  className={`status-select ${getStatusClass(order.status)}`}
                >
                  <option value="pending">Beklemede</option>
                  <option value="preparing">Siparişiniz Hazırlanıyor</option>
                  <option value="shipped">Kargolandı</option>
                  <option value="delivered">Teslim Edildi</option>
                </select>
              </td>
              <td>
                {order.shipping?.address || "Bilinmiyor"},{" "}
                {order.shipping?.city || "Bilinmiyor"},{" "}
                {order.shipping?.postalCode || "Bilinmiyor"},{" "}
                {order.shipping?.country || "Bilinmiyor"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedOrder && (
        <div className="modal-overlay">
          <div className="order-modal">
            <div className="modal-header">
              <h3>Sipariş Detayları</h3>
              <button
                className="close-button"
                onClick={() => setSelectedOrder(null)}
              >
                ×
              </button>
            </div>

            <div className="modal-content">
              <div className="customer-details">
                <h4>Müşteri Bilgileri</h4>
                <p>
                  <strong>Müşteri:</strong> {selectedOrder.customerInfo?.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedOrder.customerInfo?.email}
                </p>
                <p>
                  <strong>Telefon:</strong> {selectedOrder.customerInfo?.phone}
                </p>
              </div>

              <div className="shipping-details">
                <h4>Teslimat Bilgileri</h4>
                <p>
                  <strong>Adres:</strong> {selectedOrder.shipping?.address},{" "}
                  {selectedOrder.shipping?.city},{" "}
                  {selectedOrder.shipping?.postalCode},{" "}
                  {selectedOrder.shipping?.country}
                </p>
              </div>

              <div className="order-items">
                <h4>Sipariş Edilen Ürünler</h4>
                <div className="items-list">
                  {Array.isArray(selectedOrder.items) ? (
                    selectedOrder.items.map((item, index) => (
                      <div key={index} className="order-item">
                        <div className="item-image">
                          {item.image && (
                            <img src={item.image} alt={item.name} />
                          )}
                        </div>
                        <div className="item-details">
                          <h5>{item.name}</h5>
                          <p>
                            <strong>Miktar:</strong> {item.quantity}
                          </p>
                          <p>
                            <strong>Fiyat:</strong> {item.price}{" "}
                            {selectedOrder.payment?.currency}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Ürün bilgisi bulunamadı.</p>
                  )}
                </div>
              </div>

              <div className="order-summary">
                <h4>Sipariş Özeti</h4>
                <p>
                  <strong>Toplam Tutar:</strong> {selectedOrder.totalAmount}{" "}
                  {selectedOrder.payment?.currency}
                </p>
                <p>
                  <strong>Sipariş Durumu:</strong> {selectedOrder.status}
                </p>
                <p>
                  <strong>Sipariş Tarihi:</strong>{" "}
                  {new Date(selectedOrder.orderDate).toLocaleString()}
                </p>
              </div>

              <div className="status-update">
                <h4>Durum Güncelle</h4>
                <select
                  value={selectedOrder.status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  <option value="pending">Beklemede</option>
                  <option value="preparing">Siparişiniz Hazırlanıyor</option>
                  <option value="shipped">Kargolandı</option>
                  <option value="delivered">Teslim Edildi</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderManagement;
