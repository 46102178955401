import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useTranslation } from 'react-i18next';
import './Profile.css';

const Profile = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    email: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (currentUser?.uid) {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setFormData({
              firstName: data.firstName || '',
              lastName: data.lastName || '',
              phone: data.phone || '',
              address: data.address || '',
              email: data.email || currentUser.email
            });
          }
        }
      } catch (err) {
        setError('Kullanıcı bilgileri yüklenirken bir hata oluştu.');
        console.error('Error fetching user data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await updateDoc(doc(db, 'users', currentUser.uid), {
        ...formData,
        updatedAt: new Date().toISOString()
      });
      setUserData(formData);
      setIsEditing(false);
    } catch (err) {
      setError('Bilgileriniz güncellenirken bir hata oluştu.');
      console.error('Error updating user data:', err);
    }
  };

  if (loading) {
    return <div className="profile-loading">Yükleniyor...</div>;
  }

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h2>{t('profile.title')}</h2>
        {error && <div className="profile-error">{error}</div>}

        {isEditing ? (
          <form onSubmit={handleSubmit} className="profile-form">
            <div className="form-group">
              <label>{t('userLogin.firstName')}</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>{t('userLogin.lastName')}</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>{t('userLogin.email')}</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                disabled
              />
            </div>

            <div className="form-group">
              <label>{t('userLogin.phone')}</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>{t('userLogin.address')}</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
                rows="3"
              />
            </div>

            <div className="profile-actions">
              <button type="submit" className="save-button">
                {t('profile.save')}
              </button>
              <button 
                type="button" 
                className="cancel-button"
                onClick={() => {
                  setIsEditing(false);
                  setFormData({
                    firstName: userData.firstName || '',
                    lastName: userData.lastName || '',
                    phone: userData.phone || '',
                    address: userData.address || '',
                    email: userData.email || currentUser.email
                  });
                }}
              >
                {t('profile.cancel')}
              </button>
            </div>
          </form>
        ) : (
          <div className="profile-info">
            <div className="info-group">
              <label>{t('userLogin.firstName')}</label>
              <p>{userData?.firstName}</p>
            </div>

            <div className="info-group">
              <label>{t('userLogin.lastName')}</label>
              <p>{userData?.lastName}</p>
            </div>

            <div className="info-group">
              <label>{t('userLogin.email')}</label>
              <p>{userData?.email || currentUser?.email}</p>
            </div>

            <div className="info-group">
              <label>{t('userLogin.phone')}</label>
              <p>{userData?.phone}</p>
            </div>

            <div className="info-group">
              <label>{t('userLogin.address')}</label>
              <p>{userData?.address}</p>
            </div>

            <button 
              className="edit-button"
              onClick={() => setIsEditing(true)}
            >
              {t('profile.edit')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile; 