import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/config";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import "./Orders.css";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "orders"));
      const ordersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Verileri kontrol edelim
      console.log("Sipariş Verileri:", ordersData);
      
      // Status değerlerini kontrol edelim
      const statusValues = ordersData.map(order => order.status);
      console.log("Status Değerleri:", statusValues);

      // Tarihe göre sırala (en yeni en üstte)
      ordersData.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      setOrders(ordersData);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
      console.log("Yeni Status:", newStatus);
      
      await updateDoc(doc(db, "orders", orderId), {
        status: newStatus,
        updatedAt: new Date()
      });
      
      setOrders(orders.map(order => 
        order.id === orderId 
          ? { ...order, status: newStatus, updatedAt: new Date() }
          : order
      ));
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const statusOptions = {
    pending: "Siparişiniz Hazırlanıyor",
    preparing: "Siparişiniz Hazırlanıyor",
    shipped: "Kargolandı",
    delivered: "Teslim Edildi"
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "pending":
        return "status-pending";
      case "preparing":
        return "status-preparing";
      case "shipped":
        return "status-shipped";
      case "delivered":
        return "status-delivered";
      default:
        return "";
    }
  };

  const getStatusDisplayName = (status) => {
    return statusOptions[status] || status;
  };

  const formatDate = (date) => {
    return new Date(date?.toDate()).toLocaleString('tr-TR');
  };

  const calculateOrderTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);
  };

  const formatPrice = (price) => {
    if (!price || isNaN(price)) return "₺0,00";
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY'
    }).format(price);
  };

  const filteredOrders = orders.filter(order => {
    const matchesSearch = 
      order.customerInfo?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.orderNumber?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesFilter = 
      filterStatus === "all" || order.status === filterStatus;

    return matchesSearch && matchesFilter;
  });

  if (loading) return <div className="loading">Yükleniyor...</div>;

  return (
    <div className="orders-container">
      <div className="orders-header">
        <h2>Sipariş Yönetimi</h2>
        <div className="filters">
          <input
            type="text"
            placeholder="Sipariş veya müşteri ara..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="status-filter"
          >
            <option value="all">Tüm Durumlar</option>
            {Object.entries(statusOptions).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="orders-table">
        <table>
          <thead>
            <tr>
              <th>Sipariş No</th>
              <th>Müşteri</th>
              <th>Ürünler</th>
              <th>Toplam</th>
              <th>Tarih</th>
              <th>Durum</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id} className={getStatusBadgeClass(order.status)}>
                <td>{order.orderNumber}</td>
                <td>
                  <div className="customer-info">
                    <div>{order.customerInfo?.name}</div>
                    <div className="customer-email">{order.customerInfo?.email}</div>
                  </div>
                </td>
                <td>
                  <div className="order-items">
                    {order.items?.map((item, index) => (
                      <div key={index} className="order-item">
                        <span className="item-name">{item.name}</span>
                        <span className="item-quantity">x {item.quantity}</span>
                        <span className="item-price">{formatPrice(item.price)}</span>
                      </div>
                    ))}
                  </div>
                </td>
                <td>{formatPrice(calculateOrderTotal(order.items))}</td>
                <td>{formatDate(order.createdAt)}</td>
                <td>
                  <span className={`status-badge ${getStatusBadgeClass(order.status)}`}>
                    {getStatusDisplayName(order.status)}
                  </span>
                </td>
                <td>
                  <select
                    value={order.status || "pending"}
                    onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
                    className={`status-select ${getStatusBadgeClass(order.status)}`}
                  >
                    <option value="pending">Siparişiniz Hazırlanıyor</option>
                    <option value="preparing">Siparişiniz Hazırlanıyor</option>
                    <option value="shipped">Kargolandı</option>
                    <option value="delivered">Teslim Edildi</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Orders; 