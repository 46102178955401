import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import "./Cart.css";

const Cart = ({ isOpen, setIsOpen, cartItems, setCartItems }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleCheckout = () => {
    if (!currentUser) {
      navigate("/user/login", {
        state: { returnUrl: "/checkout" },
      });
      return;
    }

    if (!cartItems || cartItems.length === 0) {
      return;
    }

    setIsOpen(false);
    navigate("/checkout", {
      state: {
        cartItems: cartItems,
        total: calculateTotal(cartItems),
      },
    });
  };

  const handleQuantityChange = (productId, newQuantity) => {
    if (newQuantity < 1) return;

    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const removeFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
  };

  return (
    <>
      <div
        className={`cart-overlay ${isOpen ? "show" : ""}`}
        onClick={() => setIsOpen(false)}
      />
      <div className={`cart-sidebar ${isOpen ? "open" : ""}`}>
        <div className="cart-header">
          <h2>{t("cart.title")}</h2>
          <button className="close-button" onClick={() => setIsOpen(false)}>
            ×
          </button>
        </div>

        <div className="cart-items">
          {!cartItems || cartItems.length === 0 ? (
            <p className="empty-cart">{t("cart.empty")}</p>
          ) : (
            cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                <img
                  src={item.image}
                  alt={item.title}
                  className="cart-item-image"
                />
                <div className="cart-item-details">
                  <h3>{item.title}</h3>
                  <p className="cart-item-price">
                    ₺{(item.price * item.quantity).toFixed(2)}
                  </p>
                  <div className="quantity-controls">
                    <button
                      onClick={() =>
                        handleQuantityChange(item.id, item.quantity - 1)
                      }
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      onClick={() =>
                        handleQuantityChange(item.id, item.quantity + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                  <button
                    className="remove-button"
                    onClick={() => removeFromCart(item.id)}
                  >
                    {t("cart.remove")}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        {cartItems && cartItems.length > 0 && (
          <div className="cart-footer">
            <div className="cart-total">
              <h3>
                {t("cart.total")}: ₺{calculateTotal(cartItems).toFixed(2)}
              </h3>
            </div>
            <button className="checkout-button" onClick={handleCheckout}>
              {t("cart.checkout")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
