import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebase/config";
import { 
  collection, 
  addDoc, 
  getDocs,
  serverTimestamp,
  query,
  orderBy,
  onSnapshot
} from "firebase/firestore";
import { 
  ref, 
  uploadBytesResumable, 
  getDownloadURL 
} from "firebase/storage";
import "./Products.css";
import { getAuth } from "firebase/auth";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    categoryId: "",
    images: [],
    video: null,
    currency: "TRY",
    stock: 0,
    shipping: {
      domestic: {
        provider: "PTT",
        price: 0,
        estimatedDays: "2-3",
        freeShippingThreshold: 1000 // 1000 TL üzeri ücretsiz kargo
      },
      international: {
        provider: "Navlungo",
        priceByRegion: {
          europe: 0,
          americas: 0,
          asia: 0,
          other: 0
        },
        estimatedDays: "5-10"
      }
    }
  });
  const [uploadProgress, setUploadProgress] = useState({
    images: 0,
    video: 0,
  });
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    images: "",
    video: "",
  });

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();

    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const ordersRef = collection(db, "orders");
    // Basitleştirilmiş sorgu
    const q = query(
      ordersRef,
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newOrdersList = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          newOrdersList.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
          setNotifications((prev) => [
            {
              id: change.doc.id,
              type: "new-order",
              message: `Yeni sipariş: ${change.doc.data().orderNumber}`,
              time: new Date(),
            },
            ...prev,
          ]);
        }
      });
      setNewOrders(newOrdersList);
    });

    return () => unsubscribe();
  }, []);

  const handleProductSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setUploadProgress({ images: 0, video: 0 });
    setUploadStatus({
      images: 'Yükleme başlatılıyor...',
      video: newProduct.video ? 'Yükleme başlatılıyor...' : ''
    });

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('Yetkilendirme hatası: Lütfen tekrar giriş yapın');
      }

      const imageUrls = [];
      for (let i = 0; i < newProduct.images.length; i++) {
        const image = newProduct.images[i];
        const imageRef = ref(storage, `products/${user.uid}/${Date.now()}_${image.name}`);
        
        try {
          const uploadTask = uploadBytesResumable(imageRef, image);
          
          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(prev => ({
                  ...prev,
                  images: (progress + (i * 100)) / newProduct.images.length
                }));
              },
              (error) => {
                console.error("Upload error:", error);
                reject(error);
              },
              async () => {
                try {
                  const url = await getDownloadURL(uploadTask.snapshot.ref);
                  imageUrls.push(url);
                  resolve();
                } catch (error) {
                  console.error("GetDownloadURL error:", error);
                  reject(error);
                }
              }
            );
          });
        } catch (error) {
          console.error(`Error uploading image ${i}:`, error);
          throw error;
        }
      }

      let videoUrl = null;
      if (newProduct.video) {
        const videoRef = ref(storage, `products/${user.uid}/${Date.now()}_${newProduct.video.name}`);
        
        try {
          const uploadTask = uploadBytesResumable(videoRef, newProduct.video);
          
          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(prev => ({
                  ...prev,
                  video: progress
                }));
              },
              (error) => {
                console.error("Upload error:", error);
                reject(error);
              },
              async () => {
                try {
                  videoUrl = await getDownloadURL(uploadTask.snapshot.ref);
                  resolve();
                } catch (error) {
                  console.error("GetDownloadURL error:", error);
                  reject(error);
                }
              }
            );
          });
        } catch (error) {
          console.error("Error uploading video:", error);
          throw error;
        }
      }

      const productRef = await addDoc(collection(db, "products"), {
        name: newProduct.name,
        description: newProduct.description,
        categoryId: newProduct.categoryId,
        images: imageUrls,
        video: videoUrl,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        userId: user.uid,
        stock: parseInt(newProduct.stock),
        active: true
      });

      await addDoc(collection(db, `products/${productRef.id}/prices`), {
        unit_amount: Math.round(parseFloat(newProduct.price) * 100),
        currency: newProduct.currency,
        active: true,
        type: 'one_time',
        createdAt: serverTimestamp()
      });

      setNewProduct({
        name: "",
        description: "",
        price: "",
        categoryId: "",
        images: [],
        video: null,
        currency: "TRY",
        stock: 0
      });
      setUploadProgress({ images: 0, video: 0 });
      setUploadStatus({ images: '', video: '' });
      
      await fetchProducts();

    } catch (error) {
      console.error("Error adding product:", error);
      setUploadStatus({
        images: 'Yükleme sırasında hata oluştu: ' + error.message,
        video: 'Yükleme sırasında hata oluştu: ' + error.message
      });
    } finally {
      setLoading(false);
    }
  };

  // Sürükle-bırak işlevleri
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add('dragging');
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove('dragging');
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.remove('dragging');

    const files = Array.from(e.dataTransfer.files);
    
    if (type === 'image') {
      const imageFiles = files.filter(file => file.type.startsWith('image/'));
      if (imageFiles.length > 0) {
        setNewProduct(prev => ({
          ...prev,
          images: [...prev.images, ...imageFiles]
        }));
      }
    } else if (type === 'video') {
      const videoFile = files.find(file => file.type.startsWith('video/'));
      if (videoFile) {
        setNewProduct(prev => ({
          ...prev,
          video: videoFile
        }));
      }
    }
  };

  // Dosya seçimi işlevi
  const handleFileSelect = (e, type) => {
    const files = Array.from(e.target.files);
    
    if (type === 'image') {
      setNewProduct(prev => ({
        ...prev,
        images: [...prev.images, ...files]
      }));
    } else if (type === 'video') {
      setNewProduct(prev => ({
        ...prev,
        video: files[0]
      }));
    }
  };

  // Dosya seçme penceresini açma işlevi
  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  return (
    <div className="products-container">
      <div className="products-header">
        <h2>Ürün Yönetimi</h2>
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
          <p>Ürün yükleniyor, lütfen bekleyin...</p>
        </div>
      )}

      <div className="product-form-container" style={{ position: 'relative', zIndex: 2 }}>
        <h3 className="form-title">Yeni Ürün Ekle</h3>
        <form onSubmit={handleProductSubmit} className="product-form" style={{ position: 'relative', zIndex: 3 }}>
          <div className="form-group">
            <label>Ürün Adı</label>
            <input
              type="text"
              value={newProduct.name}
              onChange={(e) =>
                setNewProduct({ ...newProduct, name: e.target.value })
              }
              placeholder="Ürün adını girin"
              required
            />
          </div>

          <div className="form-group">
            <label>Kategori</label>
            <select
              value={newProduct.categoryId}
              onChange={(e) =>
                setNewProduct({ ...newProduct, categoryId: e.target.value })
              }
              required
            >
              <option value="">Kategori Seçin</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Para Birimi</label>
            <select
              value={newProduct.currency}
              onChange={(e) => setNewProduct({ ...newProduct, currency: e.target.value })}
              required
            >
              <option value="TRY">TRY (₺)</option>
              <option value="USD">USD ($)</option>
              <option value="EUR">EUR (€)</option>
            </select>
          </div>

          <div className="form-group">
            <label>Fiyat</label>
            <input
              type="number"
              step="0.01"
              min="0"
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
              placeholder="0.00"
              required
            />
          </div>

          <div className="form-group">
            <label>Stok Miktarı</label>
            <input
              type="number"
              min="0"
              value={newProduct.stock}
              onChange={(e) => setNewProduct({ ...newProduct, stock: e.target.value })}
              placeholder="0"
              required
            />
          </div>

          <div className="form-group full-width">
            <label>Ürün Açıklaması</label>
            <textarea
              value={newProduct.description}
              onChange={(e) =>
                setNewProduct({ ...newProduct, description: e.target.value })
              }
              placeholder="Ürün detaylarını girin"
              required
            />
          </div>

          <div className="form-group">
            <label>Ürün Görselleri</label>
            <div 
              className="upload-box"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, 'image')}
            >
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={(e) => handleFileSelect(e, 'image')}
                id="image-upload"
                required
              />
              <div className="upload-content">
                {newProduct.images.length > 0 ? (
                  <div className="upload-files">
                    {Array.from(newProduct.images).map((file, index) => (
                      <div key={index} className="file-item">
                        <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                        <div className="file-info">
                          <span className="file-name">{file.name}</span>
                          <span className="file-size">{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                        </div>
                        {uploadProgress.images > 0 && (
                          <div className="file-progress">
                            <div 
                              className="progress-bar" 
                              style={{ width: `${uploadProgress.images}%` }}
                            />
                            <span className="progress-text">{Math.round(uploadProgress.images)}%</span>
                          </div>
                        )}
                        {uploadStatus.images && (
                          <div className={`file-status ${uploadStatus.images.includes('hata') ? 'error' : 
                            uploadStatus.images.includes('başarıyla') ? 'success' : 'loading'}`}>
                            <i className={`fas ${
                              uploadStatus.images.includes('hata') ? 'fa-exclamation-circle' :
                              uploadStatus.images.includes('başarıyla') ? 'fa-check-circle' : 'fa-spinner fa-spin'
                            }`}></i>
                          </div>
                        )}
                      </div>
                    ))}
                    <button 
                      type="button"
                      className="add-more-files"
                      onClick={() => triggerFileInput('image-upload')}
                    >
                      <i className="fas fa-plus"></i>
                      Daha Fazla Görsel Ekle
                    </button>
                  </div>
                ) : (
                  <div className="upload-placeholder" onClick={() => triggerFileInput('image-upload')}>
                    <i className="fas fa-cloud-upload-alt"></i>
                    <span>Görselleri sürükleyin veya seçin</span>
                    <small>PNG, JPG, GIF (max. 5MB)</small>
                    <button type="button" className="select-files-btn">
                      Dosya Seç
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Video (Opsiyonel)</label>
            <div 
              className="upload-box"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={(e) => handleDrop(e, 'video')}
            >
              <input
                type="file"
                accept="video/*"
                onChange={(e) => handleFileSelect(e, 'video')}
                id="video-upload"
              />
              <div className="upload-content">
                {newProduct.video ? (
                  <div className="upload-files">
                    <div className="file-item">
                      <div className="video-preview">
                        <i className="fas fa-video"></i>
                      </div>
                      <div className="file-info">
                        <span className="file-name">{newProduct.video.name}</span>
                        <span className="file-size">
                          {(newProduct.video.size / 1024 / 1024).toFixed(2)} MB
                        </span>
                      </div>
                      {uploadProgress.video > 0 && (
                        <div className="file-progress">
                          <div 
                            className="progress-bar" 
                            style={{ width: `${uploadProgress.video}%` }}
                          />
                          <span className="progress-text">{Math.round(uploadProgress.video)}%</span>
                        </div>
                      )}
                      {uploadStatus.video && (
                        <div className={`file-status ${uploadStatus.video.includes('hata') ? 'error' : 
                          uploadStatus.video.includes('başarıyla') ? 'success' : 'loading'}`}>
                          <i className={`fas ${
                            uploadStatus.video.includes('hata') ? 'fa-exclamation-circle' :
                            uploadStatus.video.includes('başarıyla') ? 'fa-check-circle' : 'fa-spinner fa-spin'
                          }`}></i>
                        </div>
                      )}
                    </div>
                    <button 
                      type="button"
                      className="change-file"
                      onClick={() => triggerFileInput('video-upload')}
                    >
                      <i className="fas fa-sync-alt"></i>
                      Videoyu Değiştir
                    </button>
                  </div>
                ) : (
                  <div className="upload-placeholder" onClick={() => triggerFileInput('video-upload')}>
                    <i className="fas fa-video"></i>
                    <span>Video eklemek için tıklayın veya sürükleyin</span>
                    <small>MP4, WebM (max. 50MB)</small>
                    <button type="button" className="select-files-btn">
                      Video Seç
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" disabled={loading} className="submit-button">
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Ekleniyor...
                </>
              ) : (
                <>
                  <i className="fas fa-plus"></i> Ürün Ekle
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Products;
