import React from "react";
import ProductCard from "../ProductCard/ProductCard";
import Cart from "../Cart/Cart";
import "./ProCard.css";

const Products = ({ products, setIsCartOpen, cartItems, setCartItems, isCartOpen }) => {
  return (
    <div className="products-grid">
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          cartItems={cartItems}
          setCartItems={setCartItems}
          setIsCartOpen={setIsCartOpen}
        />
      ))}
      <Cart
        isOpen={isCartOpen}
        setIsOpen={setIsCartOpen}
        cartItems={cartItems}
        removeFromCart={(productId) => {
          setCartItems(cartItems.filter((item) => item.id !== productId));
        }}
        updateQuantity={(productId, newQuantity) => {
          setCartItems(
            cartItems.map((item) =>
              item.id === productId ? { ...item, quantity: newQuantity } : item
            )
          );
        }}
      />
    </div>
  );
};

export default Products;
