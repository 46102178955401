import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import "./HomeProducts.css";
import ProductCard from "../ProductCard/ProductCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PromotionBanner = () => {
  const { t } = useTranslation();

  const banners = [
    {
      id: 1,
      image: "/banners/promo1.jpg",
      title: "Sanat Eserleri",
      description:
        "Benzersiz sanat eserleriyle yaşam alanlarınızı renklendirin",
      buttonText: "Keşfet",
    },
    {
      id: 2,
      image: "/banners/promo2.jpg",
      title: "Özel Koleksiyon",
      description: "Sınırlı sayıda üretilen özel koleksiyonlarımızı inceleyin",
      buttonText: "İncele",
    },
    {
      id: 3,
      image: "/banners/promo3.jpg",
      title: "Yeni Sanatçılar",
      description: "Genç sanatçıların eserlerini keşfedin",
      buttonText: "Tümünü Gör",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <div className="promotion-banner">
      <Slider {...settings}>
        {banners.map((banner) => (
          <div key={banner.id} className="banner-slide">
            <div
              className="banner-content"
              style={{ backgroundImage: `url(${banner.image})` }}
            >
              <div className="banner-text">
                <h2>{banner.title}</h2>
                <p>{banner.description}</p>
                <button className="banner-btn">{banner.buttonText}</button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const HomeProducts = ({ cartItems, setCartItems, setIsCartOpen }) => {
  const { t } = useTranslation();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, "products");

        // Tüm ürünleri çek
        const allProductsSnapshot = await getDocs(productsRef);
        const allProducts = allProductsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Farklı kategoriler için ürünleri filtrele
        const modernArtProducts = allProducts
          .filter((p) => p.categoryId === "modernArt")
          .slice(0, 6);
        const oilPaintingProducts = allProducts
          .filter((p) => p.categoryId === "oilPainting")
          .slice(0, 6);
        const sculptureProducts = allProducts
          .filter((p) => p.categoryId === "sculpture")
          .slice(0, 6);
        const popularProducts = allProducts
          .sort(() => Math.random() - 0.5)
          .slice(0, 6); // Rastgele 6 ürün

        setSections(
          [
            {
              id: "newArrivals",
              title: "product.sections.newArrivals",
              items: allProducts.slice(0, 6),
            },
            {
              id: "featured",
              title: "product.sections.featured",
              items: allProducts.filter((p) => p.featured).slice(0, 6),
            },
            {
              id: "modernArt",
              title: "categories.modernArt",
              items: modernArtProducts,
            },
            {
              id: "oilPainting",
              title: "categories.oilPainting",
              items: oilPaintingProducts,
            },
            {
              id: "sculpture",
              title: "categories.sculpture",
              items: sculptureProducts,
            },
            {
              id: "popular",
              title: "product.sections.popular",
              items: popularProducts,
            },
            {
              id: "bestSellers",
              title: "product.sections.bestSellers",
              items: allProducts.filter((p) => p.bestSeller).slice(0, 6),
            },
            {
              id: "specialOffers",
              title: "product.sections.specialOffers",
              items: allProducts.filter((p) => p.onSale).slice(0, 6),
            },
          ].filter((section) => section.items.length > 0)
        ); // Boş bölümleri filtrele

        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const renderSection = (section) => {
    if (!section.items.length) return null;

    return (
      <section key={section.id} className="home-product-section">
        <div className="section-header">
          <h2>{t(section.title)}</h2>
          <button className="view-all-btn">{t("product.viewAll")}</button>
        </div>

        <div className="products-row">
          {section.items.map((product) => (
            <div key={product.id} className="product-item">
              <ProductCard
                product={product}
                cartItems={cartItems}
                setCartItems={setCartItems}
                setIsCartOpen={setIsCartOpen}
              />
            </div>
          ))}
        </div>
      </section>
    );
  };

  const handleAddToCart = (product) => {
    const newItem = {
      id: product.id,
      title: product.name || product.title,
      price: Number(product.price),
      image: product.image || product.images?.[0],
      quantity: 1,
      totalPrice: Number(product.price)
    };

    const existingItem = cartItems.find(item => item.id === product.id);
    
    if (existingItem) {
      setCartItems(cartItems.map(item =>
        item.id === product.id
          ? { 
              ...item, 
              quantity: item.quantity + 1,
              totalPrice: (item.quantity + 1) * item.price
            }
          : item
      ));
    } else {
      setCartItems([...cartItems, newItem]);
    }
    
    setIsCartOpen(true);
  };

  if (loading) {
    return <div className="loading">{t("product.loading")}</div>;
  }

  return (
    <div className="home-products-container">
      {sections.map((section, index) => (
        <React.Fragment key={section.id}>
          {renderSection(section)}
          {section.id === "popular" && <PromotionBanner />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default HomeProducts;
