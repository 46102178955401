import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import "./Admin.css";
import Products from "./Products/Products";
import Categories from "./Categories/Categories";
import Inventory from "./Inventory/Inventory";
import Orders from "./Orders/Orders";
import OrderManagement from "./OrderManagement";
import PaymentSettings from "./PaymentSettings/PaymentSettings";

const Dashboard = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("overview");
  const [newOrders, setNewOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [activeCustomers, setActiveCustomers] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [playNotificationSound] = useState(
    new Audio("/notification-sound.mp3")
  );
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const ordersRef = collection(db, "orders");
    const q = query(
      ordersRef,
      where("createdAt", ">=", today),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newOrdersList = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          newOrdersList.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
          setNotifications((prev) => [
            {
              id: change.doc.id,
              type: "new-order",
              message: `Yeni sipariş: ${change.doc.data().orderNumber}`,
              time: new Date(),
            },
            ...prev,
          ]);
        }
      });
      setNewOrders(newOrdersList);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const calculateStats = () => {
      const total = newOrders.reduce(
        (sum, order) => sum + (order.totalAmount || 0),
        0
      );
      setTotalSales(total);

      const uniqueCustomers = new Set(
        newOrders.map((order) => order.customerInfo?.email)
      );
      setActiveCustomers(uniqueCustomers.size);
    };

    calculateStats();
  }, [newOrders]);

  useEffect(() => {
    const fetchAllOrders = async () => {
      try {
        const ordersRef = collection(db, "orders");
        const querySnapshot = await getDocs(ordersRef);
        const ordersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchAllOrders();
  }, []);

  useEffect(() => {
    const fetchPendingOrders = async () => {
      try {
        const ordersRef = collection(db, "orders");
        const q = query(ordersRef, where("status", "==", "pending"));
        const querySnapshot = await getDocs(q);
        const pendingOrdersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPendingOrders(pendingOrdersData);
      } catch (error) {
        console.error("Error fetching pending orders:", error);
      }
    };

    fetchPendingOrders();
  }, []);

  useEffect(() => {
    const ordersRef = collection(db, "orders");
    const q = query(
      ordersRef,
      where("status", "==", "pending"),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const order = change.doc.data();
          setNotifications((prev) => [
            {
              id: change.doc.id,
              type: "new-pending-order",
              message: `Yeni Bekleyen Sipariş! Sipariş No: ${order.orderNumber}`,
              customerName: order.customerInfo?.name || "İsimsiz Müşteri",
              total: order.totalAmount || 0,
              time: new Date(),
              status: "pending",
            },
            ...prev,
          ]);

          playNotificationSound
            .play()
            .catch((err) => console.log("Ses çalınamadı:", err));

          if (Notification.permission === "granted") {
            new Notification("Yeni Sipariş!", {
              body: `${
                order.customerInfo?.name || "İsimsiz Müşteri"
              }'den yeni sipariş`,
              icon: "/logo.png",
            });
          }
        }
      });
    });

    if (
      Notification.permission !== "granted" &&
      Notification.permission !== "denied"
    ) {
      Notification.requestPermission();
    }

    return () => unsubscribe();
  }, [playNotificationSound]);

  const handleNotificationClick = (notification) => {
    if (notification.type === "new-pending-order") {
      setActiveSection("orders");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    navigate("/login");
  };

  const handlePendingOrderClick = (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    if (order) {
      setActiveSection("orderManagement");
    }
  };

  return (
    <div className="admin-dashboard">
      <div className="dashboard-sidebar">
        <div className="sidebar-header">
          <h2 style={{ color: "#fff" }}>OnurArt Panel</h2>
        </div>
        <nav className="sidebar-nav">
          <button
            className={`nav-item ${
              activeSection === "overview" ? "active" : ""
            }`}
            onClick={() => setActiveSection("overview")}
          >
            <i className="fas fa-home"></i> Genel Bakış
          </button>

          <div className="nav-group">
            <h3>Ürün Yönetimi</h3>
            <button
              className={`nav-item ${
                activeSection === "products" ? "active" : ""
              }`}
              onClick={() => setActiveSection("products")}
            >
              <i className="fas fa-box"></i> Ürünler
            </button>
            <button
              className={`nav-item ${
                activeSection === "categories" ? "active" : ""
              }`}
              onClick={() => setActiveSection("categories")}
            >
              <i className="fas fa-tags"></i> Kategoriler
            </button>
            <button
              className={`nav-item ${
                activeSection === "inventory" ? "active" : ""
              }`}
              onClick={() => setActiveSection("inventory")}
            >
              <i className="fas fa-warehouse"></i> Stok Yönetimi
            </button>
          </div>

          <div className="nav-group">
            <h3>Sipariş Yönetimi</h3>
            <button
              className={`nav-item ${
                activeSection === "orders" ? "active" : ""
              }`}
              onClick={() => setActiveSection("orders")}
            >
              <i className="fas fa-shopping-cart"></i> Siparişler
            </button>
            <button
              className={`nav-item ${
                activeSection === "orderManagement" ? "active" : ""
              }`}
              onClick={() => setActiveSection("orderManagement")}
            >
              <i className="fas fa-list"></i> Sipariş Yönetimi
            </button>
            <button
              className={`nav-item ${
                activeSection === "shipping" ? "active" : ""
              }`}
              onClick={() => setActiveSection("shipping")}
            >
              <i className="fas fa-truck"></i> Kargo Takibi
            </button>
          </div>

          <div className="nav-group">
            <h3>Ödeme Yönetimi</h3>
            <button
              className={`nav-item ${
                activeSection === "payments" ? "active" : ""
              }`}
              onClick={() => setActiveSection("payments")}
            >
              <i className="fas fa-credit-card"></i> Ödeme Ayarları
            </button>
            <button
              className={`nav-item ${
                activeSection === "transactions" ? "active" : ""
              }`}
              onClick={() => setActiveSection("transactions")}
            >
              <i className="fas fa-exchange-alt"></i> İşlem Geçmişi
            </button>
          </div>

          <div className="nav-group">
            <h3>Müşteri Yönetimi</h3>
            <button
              className={`nav-item ${
                activeSection === "customers" ? "active" : ""
              }`}
              onClick={() => setActiveSection("customers")}
            >
              <i className="fas fa-users"></i> Müşteriler
            </button>
            <button
              className={`nav-item ${
                activeSection === "reviews" ? "active" : ""
              }`}
              onClick={() => setActiveSection("reviews")}
            >
              <i className="fas fa-star"></i> Değerlendirmeler
            </button>
          </div>

          <div className="nav-group">
            <h3>Site Yönetimi</h3>
            <button
              className={`nav-item ${
                activeSection === "settings" ? "active" : ""
              }`}
              onClick={() => setActiveSection("settings")}
            >
              <i className="fas fa-cog"></i> Ayarlar
            </button>
            <button
              className={`nav-item ${
                activeSection === "analytics" ? "active" : ""
              }`}
              onClick={() => setActiveSection("analytics")}
            >
              <i className="fas fa-chart-line"></i> Analitik
            </button>
          </div>
        </nav>
      </div>

      <div className="dashboard-main">
        <div className="dashboard-header">
          <div className="header-search">
            <input type="search" placeholder="Ara..." />
          </div>
          <div className="header-actions">
            <div className="notifications-dropdown">
              <button className="notification-btn">
                <i className="fas fa-bell"></i>
                {notifications.length > 0 && (
                  <span className="badge">{notifications.length}</span>
                )}
              </button>
              {notifications.length > 0 && (
                <div className="notifications-menu">
                  {notifications.map((notification) => (
                    <div
                      key={notification.id}
                      className={`notification-item ${notification.type}`}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <i className="fas fa-shopping-cart"></i>
                      <div className="notification-content">
                        <p className="notification-title">
                          {notification.message}
                        </p>
                        <p className="notification-details">
                          Müşteri: {notification.customerName}
                          <br />
                          Tutar:{" "}
                          {new Intl.NumberFormat("tr-TR", {
                            style: "currency",
                            currency: "TRY",
                          }).format(notification.total)}
                        </p>
                        <small>
                          {new Date(notification.time).toLocaleTimeString()}
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button onClick={handleLogout} className="logout-button">
              <i className="fas fa-sign-out-alt"></i> Çıkış Yap
            </button>
          </div>
        </div>

        <div className="dashboard-content">
          {activeSection === "overview" && (
            <div className="overview-grid">
              <div className="stat-card pending-orders">
                <div className="stat-header">
                  <div className="stat-icon warning">
                    <i className="fas fa-clock"></i>
                  </div>
                  <h3>Bekleyen Siparişler</h3>
                </div>
                <div className="pending-orders-list">
                  {pendingOrders.length > 0 ? (
                    <>
                      {pendingOrders.slice(0, 3).map((order) => (
                        <div
                          key={order.id}
                          className="pending-order-item"
                          onClick={() => handlePendingOrderClick(order.id)}
                        >
                          <div className="order-brief">
                            <span className="order-customer">
                              {order.customerInfo?.name || "İsimsiz Müşteri"}
                            </span>
                            <span className="order-amount">
                              {new Intl.NumberFormat("tr-TR", {
                                style: "currency",
                                currency: "TRY",
                              }).format(order.totalAmount || 0)}
                            </span>
                          </div>
                          <small className="order-time">
                            {order.createdAt
                              ? new Date(
                                  order.createdAt.toDate()
                                ).toLocaleTimeString()
                              : ""}
                          </small>
                        </div>
                      ))}
                      {pendingOrders.length > 3 && (
                        <div
                          className="more-orders"
                          onClick={() => setActiveSection("orderManagement")}
                        >
                          Tümünü Gör... ({pendingOrders.length})
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="no-orders">
                      <i className="fas fa-check-circle"></i>
                      <p>Bekleyen Siparişiniz Bulunmamaktadır</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="stat-card">
                <div className="stat-header">
                  <div className="stat-icon sales">
                    <i className="fas fa-shopping-bag"></i>
                  </div>
                  <h3>Toplam Satış</h3>
                </div>
                <div className="stat-content">
                  <p className="stat-value">
                    {new Intl.NumberFormat("tr-TR", {
                      style: "currency",
                      currency: "TRY",
                    }).format(totalSales)}
                  </p>
                  {newOrders.length > 0 && (
                    <span className="trend positive">
                      Yeni: {newOrders.length} sipariş
                    </span>
                  )}
                </div>
              </div>

              <div className="stat-card">
                <div className="stat-header">
                  <div className="stat-icon customers">
                    <i className="fas fa-users"></i>
                  </div>
                  <h3>Aktif Müşteriler</h3>
                </div>
                <div className="stat-content">
                  <p className="stat-value">{activeCustomers}</p>
                  <span className="trend positive">
                    +8% <i className="fas fa-arrow-up"></i>
                  </span>
                </div>
              </div>

              <div className="stat-card">
                <div className="stat-header">
                  <div className="stat-icon products">
                    <i className="fas fa-box"></i>
                  </div>
                  <h3>Toplam Ürün</h3>
                </div>
                <div className="stat-content">
                  <p className="stat-value">{totalProducts}</p>
                  <span className="trend neutral">
                    0% <i className="fas fa-minus"></i>
                  </span>
                </div>
              </div>
            </div>
          )}
          {activeSection === "products" && <Products />}
          {activeSection === "categories" && <Categories />}
          {activeSection === "inventory" && <Inventory />}
          {activeSection === "orders" && <Orders />}
          {activeSection === "orderManagement" && <OrderManagement />}
          {activeSection === "payments" && <PaymentSettings />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
